/*
File generated by js-routes 1.4.14
Based on Rails 5.2.5 routes of Novo::Application
 */

(function() {
  var DeprecatedGlobbingBehavior, NodeTypes, ParameterMissing, ReservedOptions, SpecialOptionsKey, UriEncoderSegmentRegex, Utils, error, result,
    hasProp = {}.hasOwnProperty,
    slice = [].slice;

  ParameterMissing = function(message, fileName, lineNumber) {
    var instance;
    instance = new Error(message, fileName, lineNumber);
    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(instance, Object.getPrototypeOf(this));
    } else {
      instance.__proto__ = this.__proto__;
    }
    if (Error.captureStackTrace) {
      Error.captureStackTrace(instance, ParameterMissing);
    }
    return instance;
  };

  ParameterMissing.prototype = Object.create(Error.prototype, {
    constructor: {
      value: Error,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });

  if (Object.setPrototypeOf) {
    Object.setPrototypeOf(ParameterMissing, Error);
  } else {
    ParameterMissing.__proto__ = Error;
  }

  NodeTypes = {"GROUP":1,"CAT":2,"SYMBOL":3,"OR":4,"STAR":5,"LITERAL":6,"SLASH":7,"DOT":8};

  DeprecatedGlobbingBehavior = false;

  SpecialOptionsKey = "_options";

  UriEncoderSegmentRegex = /[^a-zA-Z0-9\-\._~!\$&'\(\)\*\+,;=:@]/g;

  ReservedOptions = ['anchor', 'trailing_slash', 'subdomain', 'host', 'port', 'protocol'];

  Utils = {
    configuration: {
      prefix: "",
      default_url_options: {},
      special_options_key: "_options",
      serializer: null
    },
    default_serializer: function(object, prefix) {
      var element, i, j, key, len, prop, s;
      if (prefix == null) {
        prefix = null;
      }
      if (object == null) {
        return "";
      }
      if (!prefix && !(this.get_object_type(object) === "object")) {
        throw new Error("Url parameters should be a javascript hash");
      }
      s = [];
      switch (this.get_object_type(object)) {
        case "array":
          for (i = j = 0, len = object.length; j < len; i = ++j) {
            element = object[i];
            s.push(this.default_serializer(element, prefix + "[]"));
          }
          break;
        case "object":
          for (key in object) {
            if (!hasProp.call(object, key)) continue;
            prop = object[key];
            if ((prop == null) && (prefix != null)) {
              prop = "";
            }
            if (prop != null) {
              if (prefix != null) {
                key = prefix + "[" + key + "]";
              }
              s.push(this.default_serializer(prop, key));
            }
          }
          break;
        default:
          if (object != null) {
            s.push((encodeURIComponent(prefix.toString())) + "=" + (encodeURIComponent(object.toString())));
          }
      }
      if (!s.length) {
        return "";
      }
      return s.join("&");
    },
    serialize: function(object) {
      var custom_serializer;
      custom_serializer = this.configuration.serializer;
      if ((custom_serializer != null) && this.get_object_type(custom_serializer) === "function") {
        return custom_serializer(object);
      } else {
        return this.default_serializer(object);
      }
    },
    clean_path: function(path) {
      var last_index;
      path = path.split("://");
      last_index = path.length - 1;
      path[last_index] = path[last_index].replace(/\/+/g, "/");
      return path.join("://");
    },
    extract_options: function(number_of_params, args) {
      var last_el, options;
      last_el = args[args.length - 1];
      if ((args.length > number_of_params && last_el === void 0) || ((last_el != null) && "object" === this.get_object_type(last_el) && !this.looks_like_serialized_model(last_el))) {
        options = args.pop() || {};
        delete options[this.configuration.special_options_key];
        return options;
      } else {
        return {};
      }
    },
    looks_like_serialized_model: function(object) {
      return !object[this.configuration.special_options_key] && ("id" in object || "to_param" in object);
    },
    path_identifier: function(object) {
      var property;
      if (object === 0) {
        return "0";
      }
      if (!object) {
        return "";
      }
      property = object;
      if (this.get_object_type(object) === "object") {
        if ("to_param" in object) {
          if (object.to_param == null) {
            throw new ParameterMissing("Route parameter missing: to_param");
          }
          property = object.to_param;
        } else if ("id" in object) {
          if (object.id == null) {
            throw new ParameterMissing("Route parameter missing: id");
          }
          property = object.id;
        } else {
          property = object;
        }
        if (this.get_object_type(property) === "function") {
          property = property.call(object);
        }
      }
      return property.toString();
    },
    clone: function(obj) {
      var attr, copy, key;
      if ((obj == null) || "object" !== this.get_object_type(obj)) {
        return obj;
      }
      copy = obj.constructor();
      for (key in obj) {
        if (!hasProp.call(obj, key)) continue;
        attr = obj[key];
        copy[key] = attr;
      }
      return copy;
    },
    merge: function() {
      var tap, xs;
      xs = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      tap = function(o, fn) {
        fn(o);
        return o;
      };
      if ((xs != null ? xs.length : void 0) > 0) {
        return tap({}, function(m) {
          var j, k, len, results, v, x;
          results = [];
          for (j = 0, len = xs.length; j < len; j++) {
            x = xs[j];
            results.push((function() {
              var results1;
              results1 = [];
              for (k in x) {
                v = x[k];
                results1.push(m[k] = v);
              }
              return results1;
            })());
          }
          return results;
        });
      }
    },
    normalize_options: function(parts, required_parts, default_options, actual_parameters) {
      var i, j, key, len, options, part, parts_options, result, route_parts, url_parameters, use_all_parts, value;
      options = this.extract_options(parts.length, actual_parameters);
      if (actual_parameters.length > parts.length) {
        throw new Error("Too many parameters provided for path");
      }
      use_all_parts = actual_parameters.length > required_parts.length;
      parts_options = {};
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        use_all_parts = true;
        if (this.indexOf(parts, key) >= 0) {
          parts_options[key] = value;
        }
      }
      options = this.merge(this.configuration.default_url_options, default_options, options);
      result = {};
      url_parameters = {};
      result['url_parameters'] = url_parameters;
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        value = options[key];
        if (this.indexOf(ReservedOptions, key) >= 0) {
          result[key] = value;
        } else {
          url_parameters[key] = value;
        }
      }
      route_parts = use_all_parts ? parts : required_parts;
      i = 0;
      for (j = 0, len = route_parts.length; j < len; j++) {
        part = route_parts[j];
        if (i < actual_parameters.length) {
          if (!parts_options.hasOwnProperty(part)) {
            url_parameters[part] = actual_parameters[i];
            ++i;
          }
        }
      }
      return result;
    },
    build_route: function(parts, required_parts, default_options, route, full_url, args) {
      var options, parameters, result, url, url_params;
      args = Array.prototype.slice.call(args);
      options = this.normalize_options(parts, required_parts, default_options, args);
      parameters = options['url_parameters'];
      result = "" + (this.get_prefix()) + (this.visit(route, parameters));
      url = Utils.clean_path(result);
      if (options['trailing_slash'] === true) {
        url = url.replace(/(.*?)[\/]?$/, "$1/");
      }
      if ((url_params = this.serialize(parameters)).length) {
        url += "?" + url_params;
      }
      url += options.anchor ? "#" + options.anchor : "";
      if (full_url) {
        url = this.route_url(options) + url;
      }
      return url;
    },
    visit: function(route, parameters, optional) {
      var left, left_part, right, right_part, type, value;
      if (optional == null) {
        optional = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return this.visit(left, parameters, true);
        case NodeTypes.STAR:
          return this.visit_globbing(left, parameters, true);
        case NodeTypes.LITERAL:
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
          return left;
        case NodeTypes.CAT:
          left_part = this.visit(left, parameters, optional);
          right_part = this.visit(right, parameters, optional);
          if (optional && ((this.is_optional_node(left[0]) && !left_part) || ((this.is_optional_node(right[0])) && !right_part))) {
            return "";
          }
          return "" + left_part + right_part;
        case NodeTypes.SYMBOL:
          value = parameters[left];
          delete parameters[left];
          if (value != null) {
            return this.encode_segment(this.path_identifier(value));
          }
          if (optional) {
            return "";
          } else {
            throw new ParameterMissing("Route parameter missing: " + left);
          }
          break;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    encode_segment: function(segment) {
      return segment.replace(UriEncoderSegmentRegex, function(str) {
        return encodeURIComponent(str);
      });
    },
    is_optional_node: function(node) {
      return this.indexOf([NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT], node) >= 0;
    },
    build_path_spec: function(route, wildcard) {
      var left, right, type;
      if (wildcard == null) {
        wildcard = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return "(" + (this.build_path_spec(left)) + ")";
        case NodeTypes.CAT:
          return "" + (this.build_path_spec(left)) + (this.build_path_spec(right));
        case NodeTypes.STAR:
          return this.build_path_spec(left, true);
        case NodeTypes.SYMBOL:
          if (wildcard === true) {
            return "" + (left[0] === '*' ? '' : '*') + left;
          } else {
            return ":" + left;
          }
          break;
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
        case NodeTypes.LITERAL:
          return left;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    visit_globbing: function(route, parameters, optional) {
      var left, right, type, value;
      type = route[0], left = route[1], right = route[2];
      value = parameters[left];
      delete parameters[left];
      if (value == null) {
        return this.visit(route, parameters, optional);
      }
      value = (function() {
        switch (this.get_object_type(value)) {
          case "array":
            return value.join("/");
          default:
            return value;
        }
      }).call(this);
      if (DeprecatedGlobbingBehavior) {
        return this.path_identifier(value);
      } else {
        return encodeURI(this.path_identifier(value));
      }
    },
    get_prefix: function() {
      var prefix;
      prefix = this.configuration.prefix;
      if (prefix !== "") {
        prefix = (prefix.match("/$") ? prefix : prefix + "/");
      }
      return prefix;
    },
    route: function(parts_table, default_options, route_spec, full_url) {
      var j, len, part, parts, path_fn, ref, required, required_parts;
      required_parts = [];
      parts = [];
      for (j = 0, len = parts_table.length; j < len; j++) {
        ref = parts_table[j], part = ref[0], required = ref[1];
        parts.push(part);
        if (required) {
          required_parts.push(part);
        }
      }
      path_fn = function() {
        return Utils.build_route(parts, required_parts, default_options, route_spec, full_url, arguments);
      };
      path_fn.required_params = required_parts;
      path_fn.toString = function() {
        return Utils.build_path_spec(route_spec);
      };
      return path_fn;
    },
    route_url: function(route_defaults) {
      var hostname, port, protocol, subdomain;
      if (typeof route_defaults === 'string') {
        return route_defaults;
      }
      hostname = route_defaults.host || Utils.current_host();
      if (!hostname) {
        return '';
      }
      subdomain = route_defaults.subdomain ? route_defaults.subdomain + '.' : '';
      protocol = route_defaults.protocol || Utils.current_protocol();
      port = route_defaults.port || (!route_defaults.host ? Utils.current_port() : void 0);
      port = port ? ":" + port : '';
      return protocol + "://" + subdomain + hostname + port;
    },
    has_location: function() {
      return (typeof window !== "undefined" && window !== null ? window.location : void 0) != null;
    },
    current_host: function() {
      if (this.has_location()) {
        return window.location.hostname;
      } else {
        return null;
      }
    },
    current_protocol: function() {
      if (this.has_location() && window.location.protocol !== '') {
        return window.location.protocol.replace(/:$/, '');
      } else {
        return 'http';
      }
    },
    current_port: function() {
      if (this.has_location() && window.location.port !== '') {
        return window.location.port;
      } else {
        return '';
      }
    },
    _classToTypeCache: null,
    _classToType: function() {
      var j, len, name, ref;
      if (this._classToTypeCache != null) {
        return this._classToTypeCache;
      }
      this._classToTypeCache = {};
      ref = "Boolean Number String Function Array Date RegExp Object Error".split(" ");
      for (j = 0, len = ref.length; j < len; j++) {
        name = ref[j];
        this._classToTypeCache["[object " + name + "]"] = name.toLowerCase();
      }
      return this._classToTypeCache;
    },
    get_object_type: function(obj) {
      if (this.jQuery && (this.jQuery.type != null)) {
        return this.jQuery.type(obj);
      }
      if (obj == null) {
        return "" + obj;
      }
      if (typeof obj === "object" || typeof obj === "function") {
        return this._classToType()[Object.prototype.toString.call(obj)] || "object";
      } else {
        return typeof obj;
      }
    },
    indexOf: function(array, element) {
      if (Array.prototype.indexOf) {
        return array.indexOf(element);
      } else {
        return this.indexOfImplementation(array, element);
      }
    },
    indexOfImplementation: function(array, element) {
      var el, i, j, len, result;
      result = -1;
      for (i = j = 0, len = array.length; j < len; i = ++j) {
        el = array[i];
        if (el === element) {
          result = i;
        }
      }
      return result;
    },
    namespace: function(root, namespace, routes) {
      var index, j, len, part, parts;
      parts = namespace ? namespace.split(".") : [];
      if (parts.length === 0) {
        return routes;
      }
      for (index = j = 0, len = parts.length; j < len; index = ++j) {
        part = parts[index];
        if (index < parts.length - 1) {
          root = (root[part] || (root[part] = {}));
        } else {
          return root[part] = routes;
        }
      }
    },
    configure: function(new_config) {
      return this.configuration = this.merge(this.configuration, new_config);
    },
    config: function() {
      return this.clone(this.configuration);
    },
    make: function() {
      var routes;
      routes = {
// additional_service => /additional_services/:id(.:format)
  // function(id, options)
  additional_service_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"additional_services",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
additional_service_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"additional_services",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// additional_services => /additional_services(.:format)
  // function(options)
  additional_services_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"additional_services",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
additional_services_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"additional_services",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// address => /addresses/:id(.:format)
  // function(id, options)
  address_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"addresses",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
address_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"addresses",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// addresses => /addresses(.:format)
  // function(options)
  addresses_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"addresses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
addresses_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"addresses",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// admin_countries => /admin/countries(.:format)
  // function(options)
  admin_countries_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"countries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
admin_countries_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"countries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// admin_debug => /admin/debug(.:format)
  // function(options)
  admin_debug_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"debug",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
admin_debug_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"debug",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// admin_intake_completion_stats => /admin/intake_completion_stats(.:format)
  // function(options)
  admin_intake_completion_stats_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"intake_completion_stats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
admin_intake_completion_stats_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"intake_completion_stats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// admin_marketing_dataset => /admin/marketing_dataset(.:format)
  // function(options)
  admin_marketing_dataset_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"marketing_dataset",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
admin_marketing_dataset_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"marketing_dataset",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// admin_petition_status => /admin/petition_status(.:format)
  // function(options)
  admin_petition_status_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"petition_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
admin_petition_status_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"petition_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// admin_root => /admin(.:format)
  // function(options)
  admin_root_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
admin_root_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// admin_test_error => /admin/test_error(.:format)
  // function(options)
  admin_test_error_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"test_error",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
admin_test_error_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"test_error",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// alias => /aliases/:id(.:format)
  // function(id, options)
  alias_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"aliases",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
alias_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"aliases",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// aliases => /aliases(.:format)
  // function(options)
  aliases_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"aliases",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
aliases_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"aliases",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// approval_notice => /approval_notices/:id(.:format)
  // function(id, options)
  approval_notice_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"approval_notices",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
approval_notice_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"approval_notices",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// approval_notices => /approval_notices(.:format)
  // function(options)
  approval_notices_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"approval_notices",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
approval_notices_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"approval_notices",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// archive_deal => /deals/:id/archive(.:format)
  // function(id, options)
  archive_deal_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"deals",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"archive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
archive_deal_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"deals",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"archive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// archive_intake => /intakes/:id/archive(.:format)
  // function(id, options)
  archive_intake_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"archive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
archive_intake_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"archive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// archive_petition => /petitions/:id/archive(.:format)
  // function(id, options)
  archive_petition_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"archive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
archive_petition_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"archive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// area => /areas/:id(.:format)
  // function(id, options)
  area_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"areas",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
area_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"areas",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// areas => /areas(.:format)
  // function(options)
  areas_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"areas",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
areas_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"areas",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// bank_statement => /bank_statements/:id(.:format)
  // function(id, options)
  bank_statement_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"bank_statements",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
bank_statement_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"bank_statements",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// bank_statements => /bank_statements(.:format)
  // function(options)
  bank_statements_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"bank_statements",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
bank_statements_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"bank_statements",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// cancel_user_registration => /users/cancel(.:format)
  // function(options)
  cancel_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
cancel_user_registration_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// client => /clients/:id(.:format)
  // function(id, options)
  client_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
client_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// clients => /clients(.:format)
  // function(options)
  clients_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"clients",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
clients_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"clients",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// confirm => /confirm(.:format)
  // function(options)
  confirm_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"confirm",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
confirm_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"confirm",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// confirm_submit_intake => /intakes/:id/confirm_submit(.:format)
  // function(id, options)
  confirm_submit_intake_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"confirm_submit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
confirm_submit_intake_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"confirm_submit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// create_referral => /create_referral(.:format)
  // function(options)
  create_referral_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"create_referral",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
create_referral_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"create_referral",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// dashboard => /dashboard(.:format)
  // function(options)
  dashboard_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dashboard",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
dashboard_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dashboard",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// dashboard_petitions => /dashboard_petitions(.:format)
  // function(options)
  dashboard_petitions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dashboard_petitions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
dashboard_petitions_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dashboard_petitions",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// dataset => /datasets/:id(.:format)
  // function(id, options)
  dataset_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"datasets",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
dataset_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"datasets",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// datasets => /datasets(.:format)
  // function(options)
  datasets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"datasets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
datasets_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"datasets",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// deal => /deals/:id(.:format)
  // function(id, options)
  deal_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"deals",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
deal_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"deals",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// deal_discount => /deal_discounts/:id(.:format)
  // function(id, options)
  deal_discount_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"deal_discounts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
deal_discount_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"deal_discounts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// deals => /deals(.:format)
  // function(options)
  deals_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"deals",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
deals_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"deals",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// degree => /degrees/:id(.:format)
  // function(id, options)
  degree_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"degrees",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
degree_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"degrees",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// degrees => /degrees(.:format)
  // function(options)
  degrees_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"degrees",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
degrees_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"degrees",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// denied_visa => /denied_visas/:id(.:format)
  // function(id, options)
  denied_visa_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"denied_visas",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
denied_visa_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"denied_visas",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// denied_visas => /denied_visas(.:format)
  // function(options)
  denied_visas_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"denied_visas",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
denied_visas_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"denied_visas",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// dependent => /dependents/:id(.:format)
  // function(id, options)
  dependent_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"dependents",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
dependent_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"dependents",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// dependents => /dependents(.:format)
  // function(options)
  dependents_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dependents",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
dependents_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dependents",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// destroy_user_session => /users/sign_out(.:format)
  // function(options)
  destroy_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
destroy_user_session_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// dev_style_guide => /dev/style_guide(.:format)
  // function(options)
  dev_style_guide_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dev",false],[2,[7,"/",false],[2,[6,"style_guide",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
dev_style_guide_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dev",false],[2,[7,"/",false],[2,[6,"style_guide",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// dev_style_guide_base => /dev/style_guide/base(.:format)
  // function(options)
  dev_style_guide_base_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dev",false],[2,[7,"/",false],[2,[6,"style_guide",false],[2,[7,"/",false],[2,[6,"base",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
dev_style_guide_base_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dev",false],[2,[7,"/",false],[2,[6,"style_guide",false],[2,[7,"/",false],[2,[6,"base",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// dev_style_guide_finished => /dev/style_guide/finished(.:format)
  // function(options)
  dev_style_guide_finished_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dev",false],[2,[7,"/",false],[2,[6,"style_guide",false],[2,[7,"/",false],[2,[6,"finished",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
dev_style_guide_finished_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dev",false],[2,[7,"/",false],[2,[6,"style_guide",false],[2,[7,"/",false],[2,[6,"finished",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// dev_style_guide_layout => /dev/style_guide/layout(.:format)
  // function(options)
  dev_style_guide_layout_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dev",false],[2,[7,"/",false],[2,[6,"style_guide",false],[2,[7,"/",false],[2,[6,"layout",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
dev_style_guide_layout_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dev",false],[2,[7,"/",false],[2,[6,"style_guide",false],[2,[7,"/",false],[2,[6,"layout",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// dev_style_guide_modules => /dev/style_guide/modules(.:format)
  // function(options)
  dev_style_guide_modules_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dev",false],[2,[7,"/",false],[2,[6,"style_guide",false],[2,[7,"/",false],[2,[6,"modules",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
dev_style_guide_modules_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dev",false],[2,[7,"/",false],[2,[6,"style_guide",false],[2,[7,"/",false],[2,[6,"modules",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// dev_style_guide_pages => /dev/style_guide/pages(.:format)
  // function(options)
  dev_style_guide_pages_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dev",false],[2,[7,"/",false],[2,[6,"style_guide",false],[2,[7,"/",false],[2,[6,"pages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
dev_style_guide_pages_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dev",false],[2,[7,"/",false],[2,[6,"style_guide",false],[2,[7,"/",false],[2,[6,"pages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// dev_style_guide_sample_page_name => /dev/style_guide/sample_page_name(.:format)
  // function(options)
  dev_style_guide_sample_page_name_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dev",false],[2,[7,"/",false],[2,[6,"style_guide",false],[2,[7,"/",false],[2,[6,"sample_page_name",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
dev_style_guide_sample_page_name_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dev",false],[2,[7,"/",false],[2,[6,"style_guide",false],[2,[7,"/",false],[2,[6,"sample_page_name",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// dev_style_guide_sample_page_tasks => /dev/style_guide/sample_page_tasks(.:format)
  // function(options)
  dev_style_guide_sample_page_tasks_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dev",false],[2,[7,"/",false],[2,[6,"style_guide",false],[2,[7,"/",false],[2,[6,"sample_page_tasks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
dev_style_guide_sample_page_tasks_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dev",false],[2,[7,"/",false],[2,[6,"style_guide",false],[2,[7,"/",false],[2,[6,"sample_page_tasks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// dev_style_guide_welcome => /dev/style_guide/welcome(.:format)
  // function(options)
  dev_style_guide_welcome_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dev",false],[2,[7,"/",false],[2,[6,"style_guide",false],[2,[7,"/",false],[2,[6,"welcome",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
dev_style_guide_welcome_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dev",false],[2,[7,"/",false],[2,[6,"style_guide",false],[2,[7,"/",false],[2,[6,"welcome",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// discount_codes => /discount_codes(.:format)
  // function(options)
  discount_codes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"discount_codes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
discount_codes_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"discount_codes",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// docs => /docs(.:format)
  // function(options)
  docs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"docs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
docs_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"docs",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// ds2019 => /ds2019s/:id(.:format)
  // function(id, options)
  ds2019_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"ds2019s",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
ds2019_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"ds2019s",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// ds2019s => /ds2019s(.:format)
  // function(options)
  ds2019s_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ds2019s",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
ds2019s_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ds2019s",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// edit_address => /addresses/:id/edit(.:format)
  // function(id, options)
  edit_address_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"addresses",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_address_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"addresses",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// edit_alias => /aliases/:id/edit(.:format)
  // function(id, options)
  edit_alias_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"aliases",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_alias_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"aliases",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// edit_approval_notice => /approval_notices/:id/edit(.:format)
  // function(id, options)
  edit_approval_notice_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"approval_notices",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_approval_notice_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"approval_notices",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// edit_area => /areas/:id/edit(.:format)
  // function(id, options)
  edit_area_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"areas",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_area_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"areas",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// edit_bank_statement => /bank_statements/:id/edit(.:format)
  // function(id, options)
  edit_bank_statement_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"bank_statements",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_bank_statement_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"bank_statements",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// edit_client => /clients/:id/edit(.:format)
  // function(id, options)
  edit_client_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_client_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// edit_dataset => /datasets/:id/edit(.:format)
  // function(id, options)
  edit_dataset_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"datasets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_dataset_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"datasets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// edit_degree => /degrees/:id/edit(.:format)
  // function(id, options)
  edit_degree_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"degrees",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_degree_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"degrees",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// edit_denied_visa => /denied_visas/:id/edit(.:format)
  // function(id, options)
  edit_denied_visa_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"denied_visas",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_denied_visa_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"denied_visas",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// edit_dependent => /dependents/:id/edit(.:format)
  // function(id, options)
  edit_dependent_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"dependents",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_dependent_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"dependents",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// edit_ds2019 => /ds2019s/:id/edit(.:format)
  // function(id, options)
  edit_ds2019_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"ds2019s",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_ds2019_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"ds2019s",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// edit_education_evaluation => /education_evaluations/:id/edit(.:format)
  // function(id, options)
  edit_education_evaluation_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"education_evaluations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_education_evaluation_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"education_evaluations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// edit_email_address => /email_addresses/:id/edit(.:format)
  // function(id, options)
  edit_email_address_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"email_addresses",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_email_address_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"email_addresses",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// edit_employee => /employees/:id/edit(.:format)
  // function(id, options)
  edit_employee_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_employee_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// edit_employment_authorization => /employment_authorizations/:id/edit(.:format)
  // function(id, options)
  edit_employment_authorization_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employment_authorizations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_employment_authorization_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employment_authorizations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// edit_entry_stamp => /entry_stamps/:id/edit(.:format)
  // function(id, options)
  edit_entry_stamp_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"entry_stamps",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_entry_stamp_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"entry_stamps",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// edit_filed_petition => /filed_petitions/:id/edit(.:format)
  // function(id, options)
  edit_filed_petition_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"filed_petitions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_filed_petition_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"filed_petitions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// edit_financial_statement => /financial_statements/:id/edit(.:format)
  // function(id, options)
  edit_financial_statement_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financial_statements",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_financial_statement_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financial_statements",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// edit_i20 => /i20s/:id/edit(.:format)
  // function(id, options)
  edit_i20_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"i20s",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_i20_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"i20s",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// edit_intake => /intakes/:id/edit(.:format)
  // function(id, options)
  edit_intake_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_intake_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// edit_intake_instruction => /intakes/:intake_id/instructions/:id/edit(.:format)
  // function(intake_id, id, options)
  edit_intake_instruction_path: Utils.route([["intake_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"intake_id",false],[2,[7,"/",false],[2,[6,"instructions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
edit_intake_instruction_url: Utils.route([["intake_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"intake_id",false],[2,[7,"/",false],[2,[6,"instructions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// edit_intake_section => /intakes/:intake_id/sections/:name/edit(.:format)
  // function(intake_id, name, options)
  edit_intake_section_path: Utils.route([["intake_id",true],["name",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"intake_id",false],[2,[7,"/",false],[2,[6,"sections",false],[2,[7,"/",false],[2,[3,"name",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
edit_intake_section_url: Utils.route([["intake_id",true],["name",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"intake_id",false],[2,[7,"/",false],[2,[6,"sections",false],[2,[7,"/",false],[2,[3,"name",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// edit_intake_section_step => /intakes/:intake_id/sections/:section_name/steps/:name/edit(.:format)
  // function(intake_id, section_name, name, options)
  edit_intake_section_step_path: Utils.route([["intake_id",true],["section_name",true],["name",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"intake_id",false],[2,[7,"/",false],[2,[6,"sections",false],[2,[7,"/",false],[2,[3,"section_name",false],[2,[7,"/",false],[2,[6,"steps",false],[2,[7,"/",false],[2,[3,"name",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
edit_intake_section_step_url: Utils.route([["intake_id",true],["section_name",true],["name",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"intake_id",false],[2,[7,"/",false],[2,[6,"sections",false],[2,[7,"/",false],[2,[3,"section_name",false],[2,[7,"/",false],[2,[6,"steps",false],[2,[7,"/",false],[2,[3,"name",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]], true),
// edit_intake_section_step_item => /intakes/:intake_id/sections/:section_name/steps/:step_name/items/:id/edit(.:format)
  // function(intake_id, section_name, step_name, id, options)
  edit_intake_section_step_item_path: Utils.route([["intake_id",true],["section_name",true],["step_name",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"intake_id",false],[2,[7,"/",false],[2,[6,"sections",false],[2,[7,"/",false],[2,[3,"section_name",false],[2,[7,"/",false],[2,[6,"steps",false],[2,[7,"/",false],[2,[3,"step_name",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]]),
edit_intake_section_step_item_url: Utils.route([["intake_id",true],["section_name",true],["step_name",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"intake_id",false],[2,[7,"/",false],[2,[6,"sections",false],[2,[7,"/",false],[2,[3,"section_name",false],[2,[7,"/",false],[2,[6,"steps",false],[2,[7,"/",false],[2,[3,"step_name",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]], true),
// edit_judging_event => /judging_events/:id/edit(.:format)
  // function(id, options)
  edit_judging_event_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"judging_events",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_judging_event_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"judging_events",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// edit_lca => /lcas/:id/edit(.:format)
  // function(id, options)
  edit_lca_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lcas",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_lca_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lcas",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// edit_mapping => /mappings/:id/edit(.:format)
  // function(id, options)
  edit_mapping_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"mappings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_mapping_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"mappings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// edit_occupation => /occupations/:id/edit(.:format)
  // function(id, options)
  edit_occupation_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"occupations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_occupation_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"occupations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// edit_organization => /organizations/:id/edit(.:format)
  // function(id, options)
  edit_organization_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_organization_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// edit_organization_address => /organizations/:organization_id/addresses/:id/edit(.:format)
  // function(organization_id, id, options)
  edit_organization_address_path: Utils.route([["organization_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"addresses",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
edit_organization_address_url: Utils.route([["organization_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"addresses",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// edit_organization_affiliation => /organizations/:organization_id/affiliations/:id/edit(.:format)
  // function(organization_id, id, options)
  edit_organization_affiliation_path: Utils.route([["organization_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"affiliations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
edit_organization_affiliation_url: Utils.route([["organization_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"affiliations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// edit_organization_bank_statement => /organizations/:organization_id/bank_statements/:id/edit(.:format)
  // function(organization_id, id, options)
  edit_organization_bank_statement_path: Utils.route([["organization_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"bank_statements",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
edit_organization_bank_statement_url: Utils.route([["organization_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"bank_statements",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// edit_organization_contact_detail => /organizations/:organization_id/contact_detail/edit(.:format)
  // function(organization_id, options)
  edit_organization_contact_detail_path: Utils.route([["organization_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"contact_detail",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
edit_organization_contact_detail_url: Utils.route([["organization_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"contact_detail",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// edit_organization_financial_statement => /organizations/:organization_id/financial_statements/:id/edit(.:format)
  // function(organization_id, id, options)
  edit_organization_financial_statement_path: Utils.route([["organization_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"financial_statements",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
edit_organization_financial_statement_url: Utils.route([["organization_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"financial_statements",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// edit_organization_history => /organizations/:organization_id/history/edit(.:format)
  // function(organization_id, options)
  edit_organization_history_path: Utils.route([["organization_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"history",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
edit_organization_history_url: Utils.route([["organization_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"history",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// edit_organization_payroll_record => /organizations/:organization_id/payroll_records/:id/edit(.:format)
  // function(organization_id, id, options)
  edit_organization_payroll_record_path: Utils.route([["organization_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"payroll_records",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
edit_organization_payroll_record_url: Utils.route([["organization_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"payroll_records",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// edit_organization_person => /organizations/:organization_id/person/edit(.:format)
  // function(organization_id, options)
  edit_organization_person_path: Utils.route([["organization_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"person",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
edit_organization_person_url: Utils.route([["organization_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"person",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// edit_organization_press => /organizations/:organization_id/press/:id/edit(.:format)
  // function(organization_id, id, options)
  edit_organization_press_path: Utils.route([["organization_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"press",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
edit_organization_press_url: Utils.route([["organization_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"press",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// edit_organization_profile => /organizations/:organization_id/profile/edit(.:format)
  // function(organization_id, options)
  edit_organization_profile_path: Utils.route([["organization_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"profile",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
edit_organization_profile_url: Utils.route([["organization_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"profile",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// edit_passport => /passports/:id/edit(.:format)
  // function(id, options)
  edit_passport_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"passports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_passport_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"passports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// edit_pay_stub => /pay_stubs/:id/edit(.:format)
  // function(id, options)
  edit_pay_stub_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"pay_stubs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_pay_stub_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"pay_stubs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// edit_person => /people/:id/edit(.:format)
  // function(id, options)
  edit_person_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_person_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// edit_person_accelerator => /people/:person_id/accelerators/:id/edit(.:format)
  // function(person_id, id, options)
  edit_person_accelerator_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"accelerators",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
edit_person_accelerator_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"accelerators",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// edit_person_address => /people/:person_id/addresses/:id/edit(.:format)
  // function(person_id, id, options)
  edit_person_address_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"addresses",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
edit_person_address_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"addresses",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// edit_person_alias => /people/:person_id/aliases/:id/edit(.:format)
  // function(person_id, id, options)
  edit_person_alias_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"aliases",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
edit_person_alias_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"aliases",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// edit_person_approval_notice => /people/:person_id/approval_notices/:id/edit(.:format)
  // function(person_id, id, options)
  edit_person_approval_notice_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"approval_notices",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
edit_person_approval_notice_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"approval_notices",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// edit_person_association => /people/:person_id/associations/:id/edit(.:format)
  // function(person_id, id, options)
  edit_person_association_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"associations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
edit_person_association_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"associations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// edit_person_attorney => /people/:person_id/attorney/edit(.:format)
  // function(person_id, options)
  edit_person_attorney_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"attorney",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
edit_person_attorney_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"attorney",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// edit_person_award => /people/:person_id/awards/:id/edit(.:format)
  // function(person_id, id, options)
  edit_person_award_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"awards",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
edit_person_award_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"awards",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// edit_person_beneficiary => /people/:person_id/beneficiary/edit(.:format)
  // function(person_id, options)
  edit_person_beneficiary_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"beneficiary",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
edit_person_beneficiary_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"beneficiary",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// edit_person_contact_detail => /people/:person_id/contact_detail/edit(.:format)
  // function(person_id, options)
  edit_person_contact_detail_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"contact_detail",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
edit_person_contact_detail_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"contact_detail",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// edit_person_critical_employment => /people/:person_id/critical_employments/:id/edit(.:format)
  // function(person_id, id, options)
  edit_person_critical_employment_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"critical_employments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
edit_person_critical_employment_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"critical_employments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// edit_person_degree => /people/:person_id/degrees/:id/edit(.:format)
  // function(person_id, id, options)
  edit_person_degree_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"degrees",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
edit_person_degree_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"degrees",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// edit_person_denied_visa => /people/:person_id/denied_visas/:id/edit(.:format)
  // function(person_id, id, options)
  edit_person_denied_visa_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"denied_visas",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
edit_person_denied_visa_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"denied_visas",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// edit_person_dependent => /people/:person_id/dependents/:id/edit(.:format)
  // function(person_id, id, options)
  edit_person_dependent_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"dependents",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
edit_person_dependent_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"dependents",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// edit_person_ds2019 => /people/:person_id/ds2019s/:id/edit(.:format)
  // function(person_id, id, options)
  edit_person_ds2019_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"ds2019s",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
edit_person_ds2019_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"ds2019s",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// edit_person_education_evaluation => /people/:person_id/education_evaluations/:id/edit(.:format)
  // function(person_id, id, options)
  edit_person_education_evaluation_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"education_evaluations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
edit_person_education_evaluation_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"education_evaluations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// edit_person_employment => /people/:person_id/employment/edit(.:format)
  // function(person_id, options)
  edit_person_employment_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"employment",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
edit_person_employment_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"employment",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// edit_person_employment_authorization => /people/:person_id/employment_authorizations/:id/edit(.:format)
  // function(person_id, id, options)
  edit_person_employment_authorization_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"employment_authorizations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
edit_person_employment_authorization_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"employment_authorizations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// edit_person_entry_stamp => /people/:person_id/entry_stamps/:id/edit(.:format)
  // function(person_id, id, options)
  edit_person_entry_stamp_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"entry_stamps",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
edit_person_entry_stamp_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"entry_stamps",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// edit_person_filed_petition => /people/:person_id/filed_petitions/:id/edit(.:format)
  // function(person_id, id, options)
  edit_person_filed_petition_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"filed_petitions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
edit_person_filed_petition_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"filed_petitions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// edit_person_high_remuneration_employment => /people/:person_id/high_remuneration_employments/:id/edit(.:format)
  // function(person_id, id, options)
  edit_person_high_remuneration_employment_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"high_remuneration_employments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
edit_person_high_remuneration_employment_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"high_remuneration_employments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// edit_person_history => /people/:person_id/history/edit(.:format)
  // function(person_id, options)
  edit_person_history_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"history",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
edit_person_history_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"history",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// edit_person_i20 => /people/:person_id/i20s/:id/edit(.:format)
  // function(person_id, id, options)
  edit_person_i20_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"i20s",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
edit_person_i20_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"i20s",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// edit_person_judging_event => /people/:person_id/judging_events/:id/edit(.:format)
  // function(person_id, id, options)
  edit_person_judging_event_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"judging_events",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
edit_person_judging_event_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"judging_events",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// edit_person_overview => /people/:person_id/overview/edit(.:format)
  // function(person_id, options)
  edit_person_overview_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"overview",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
edit_person_overview_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"overview",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// edit_person_passport => /people/:person_id/passports/:id/edit(.:format)
  // function(person_id, id, options)
  edit_person_passport_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"passports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
edit_person_passport_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"passports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// edit_person_patent => /people/:person_id/patents/:id/edit(.:format)
  // function(person_id, id, options)
  edit_person_patent_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"patents",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
edit_person_patent_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"patents",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// edit_person_pay_stub => /people/:person_id/pay_stubs/:id/edit(.:format)
  // function(person_id, id, options)
  edit_person_pay_stub_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"pay_stubs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
edit_person_pay_stub_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"pay_stubs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// edit_person_press => /people/:person_id/press/:id/edit(.:format)
  // function(person_id, id, options)
  edit_person_press_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"press",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
edit_person_press_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"press",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// edit_person_proof_of_residency => /people/:person_id/proof_of_residencies/:id/edit(.:format)
  // function(person_id, id, options)
  edit_person_proof_of_residency_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"proof_of_residencies",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
edit_person_proof_of_residency_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"proof_of_residencies",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// edit_person_scholarly_article => /people/:person_id/scholarly_articles/:id/edit(.:format)
  // function(person_id, id, options)
  edit_person_scholarly_article_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"scholarly_articles",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
edit_person_scholarly_article_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"scholarly_articles",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// edit_person_stay => /people/:person_id/stays/:id/edit(.:format)
  // function(person_id, id, options)
  edit_person_stay_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"stays",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
edit_person_stay_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"stays",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// edit_person_venture_funding => /people/:person_id/venture_fundings/:id/edit(.:format)
  // function(person_id, id, options)
  edit_person_venture_funding_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"venture_fundings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
edit_person_venture_funding_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"venture_fundings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// edit_person_venture_funding_funding_document => /people/:person_id/venture_fundings/:venture_funding_id/funding_documents/:id/edit(.:format)
  // function(person_id, venture_funding_id, id, options)
  edit_person_venture_funding_funding_document_path: Utils.route([["person_id",true],["venture_funding_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"venture_fundings",false],[2,[7,"/",false],[2,[3,"venture_funding_id",false],[2,[7,"/",false],[2,[6,"funding_documents",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
edit_person_venture_funding_funding_document_url: Utils.route([["person_id",true],["venture_funding_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"venture_fundings",false],[2,[7,"/",false],[2,[3,"venture_funding_id",false],[2,[7,"/",false],[2,[6,"funding_documents",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]], true),
// edit_person_visa_status => /people/:person_id/visa_statuses/:id/edit(.:format)
  // function(person_id, id, options)
  edit_person_visa_status_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"visa_statuses",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
edit_person_visa_status_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"visa_statuses",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// edit_petition => /petitions/:id/edit(.:format)
  // function(id, options)
  edit_petition_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_petition_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// edit_petition_advisory_opinion => /petitions/:petition_id/advisory_opinion/edit(.:format)
  // function(petition_id, options)
  edit_petition_advisory_opinion_path: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"advisory_opinion",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
edit_petition_advisory_opinion_url: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"advisory_opinion",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// edit_petition_attorney => /petitions/:petition_id/attorney/edit(.:format)
  // function(petition_id, options)
  edit_petition_attorney_path: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"attorney",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
edit_petition_attorney_url: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"attorney",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// edit_petition_attribute => /petitions/:petition_id/attributes/:id/edit(.:format)
  // function(petition_id, id, options)
  edit_petition_attribute_path: Utils.route([["petition_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"attributes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
edit_petition_attribute_url: Utils.route([["petition_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"attributes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// edit_petition_beneficiary => /petitions/:petition_id/beneficiary/edit(.:format)
  // function(petition_id, options)
  edit_petition_beneficiary_path: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"beneficiary",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
edit_petition_beneficiary_url: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"beneficiary",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// edit_petition_case_status => /petitions/:petition_id/case_status/edit(.:format)
  // function(petition_id, options)
  edit_petition_case_status_path: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"case_status",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
edit_petition_case_status_url: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"case_status",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// edit_petition_contact => /petitions/:petition_id/contacts/:name/edit(.:format)
  // function(petition_id, name, options)
  edit_petition_contact_path: Utils.route([["petition_id",true],["name",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"name",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
edit_petition_contact_url: Utils.route([["petition_id",true],["name",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"name",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// edit_petition_event => /petitions/:petition_id/events/:id/edit(.:format)
  // function(petition_id, id, options)
  edit_petition_event_path: Utils.route([["petition_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"events",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
edit_petition_event_url: Utils.route([["petition_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"events",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// edit_petition_evidence_request => /petitions/:petition_id/evidence_request/edit(.:format)
  // function(petition_id, options)
  edit_petition_evidence_request_path: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"evidence_request",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
edit_petition_evidence_request_url: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"evidence_request",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// edit_petition_history => /petitions/:petition_id/history/edit(.:format)
  // function(petition_id, options)
  edit_petition_history_path: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"history",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
edit_petition_history_url: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"history",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// edit_petition_mailer => /petitions/:petition_id/mailers/:id/edit(.:format)
  // function(petition_id, id, options)
  edit_petition_mailer_path: Utils.route([["petition_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"mailers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
edit_petition_mailer_url: Utils.route([["petition_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"mailers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// edit_petition_offer => /petitions/:petition_id/offer/edit(.:format)
  // function(petition_id, options)
  edit_petition_offer_path: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"offer",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
edit_petition_offer_url: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"offer",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// edit_petition_overview => /petitions/:petition_id/overview/edit(.:format)
  // function(petition_id, options)
  edit_petition_overview_path: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"overview",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
edit_petition_overview_url: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"overview",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// edit_petition_representative => /petitions/:petition_id/representative/edit(.:format)
  // function(petition_id, options)
  edit_petition_representative_path: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"representative",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
edit_petition_representative_url: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"representative",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// edit_petition_signatory => /petitions/:petition_id/signatory/edit(.:format)
  // function(petition_id, options)
  edit_petition_signatory_path: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"signatory",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
edit_petition_signatory_url: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"signatory",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// edit_press => /press/:id/edit(.:format)
  // function(id, options)
  edit_press_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"press",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_press_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"press",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// edit_proof_of_residency => /proof_of_residencies/:id/edit(.:format)
  // function(id, options)
  edit_proof_of_residency_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"proof_of_residencies",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_proof_of_residency_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"proof_of_residencies",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// edit_schema => /schema/edit(.:format)
  // function(options)
  edit_schema_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"schema",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
edit_schema_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"schema",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// edit_setting => /settings/:id/edit(.:format)
  // function(id, options)
  edit_setting_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_setting_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// edit_shipment => /shipments/:id/edit(.:format)
  // function(id, options)
  edit_shipment_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"shipments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_shipment_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"shipments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// edit_stay => /stays/:id/edit(.:format)
  // function(id, options)
  edit_stay_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"stays",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_stay_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"stays",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// edit_type_petitions => /petitions/edit_type(.:format)
  // function(options)
  edit_type_petitions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[6,"edit_type",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
edit_type_petitions_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[6,"edit_type",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// edit_user => /users/:id/edit(.:format)
  // function(id, options)
  edit_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_user_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// edit_user_password => /users/password/edit(.:format)
  // function(options)
  edit_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_user_password_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// edit_user_registration => /users/edit(.:format)
  // function(options)
  edit_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
edit_user_registration_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// edit_visa_status => /visa_statuses/:id/edit(.:format)
  // function(id, options)
  edit_visa_status_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"visa_statuses",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_visa_status_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"visa_statuses",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// edit_workflow => /workflows/:id/edit(.:format)
  // function(id, options)
  edit_workflow_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"workflows",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_workflow_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"workflows",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// edit_workflow_history => /workflows/:workflow_id/history/edit(.:format)
  // function(workflow_id, options)
  edit_workflow_history_path: Utils.route([["workflow_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"workflows",false],[2,[7,"/",false],[2,[3,"workflow_id",false],[2,[7,"/",false],[2,[6,"history",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
edit_workflow_history_url: Utils.route([["workflow_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"workflows",false],[2,[7,"/",false],[2,[3,"workflow_id",false],[2,[7,"/",false],[2,[6,"history",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// edit_worksite => /worksites/:id/edit(.:format)
  // function(id, options)
  edit_worksite_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"worksites",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_worksite_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"worksites",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// education_evaluation => /education_evaluations/:id(.:format)
  // function(id, options)
  education_evaluation_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"education_evaluations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
education_evaluation_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"education_evaluations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// education_evaluations => /education_evaluations(.:format)
  // function(options)
  education_evaluations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"education_evaluations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
education_evaluations_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"education_evaluations",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// email_address => /email_addresses/:id(.:format)
  // function(id, options)
  email_address_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"email_addresses",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
email_address_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"email_addresses",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// email_addresses => /email_addresses(.:format)
  // function(options)
  email_addresses_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"email_addresses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
email_addresses_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"email_addresses",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// employee => /employees/:id(.:format)
  // function(id, options)
  employee_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
employee_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// employees => /employees(.:format)
  // function(options)
  employees_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
employees_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// employment_authorization => /employment_authorizations/:id(.:format)
  // function(id, options)
  employment_authorization_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employment_authorizations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
employment_authorization_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employment_authorizations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// employment_authorizations => /employment_authorizations(.:format)
  // function(options)
  employment_authorizations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"employment_authorizations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
employment_authorizations_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"employment_authorizations",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// entity => /entity/:id(.:format)
  // function(id, options)
  entity_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"entity",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
entity_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"entity",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// entity_attributes => /entity/:entity_id/attributes(.:format)
  // function(entity_id, options)
  entity_attributes_path: Utils.route([["entity_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"entity",false],[2,[7,"/",false],[2,[3,"entity_id",false],[2,[7,"/",false],[2,[6,"attributes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
entity_attributes_url: Utils.route([["entity_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"entity",false],[2,[7,"/",false],[2,[3,"entity_id",false],[2,[7,"/",false],[2,[6,"attributes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// entity_index => /entity(.:format)
  // function(options)
  entity_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"entity",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
entity_index_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"entity",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// entry_stamp => /entry_stamps/:id(.:format)
  // function(id, options)
  entry_stamp_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"entry_stamps",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
entry_stamp_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"entry_stamps",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// entry_stamps => /entry_stamps(.:format)
  // function(options)
  entry_stamps_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"entry_stamps",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
entry_stamps_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"entry_stamps",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// expired => /expired(.:format)
  // function(options)
  expired_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"expired",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
expired_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"expired",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// filed_petition => /filed_petitions/:id(.:format)
  // function(id, options)
  filed_petition_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"filed_petitions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
filed_petition_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"filed_petitions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// filed_petitions => /filed_petitions(.:format)
  // function(options)
  filed_petitions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"filed_petitions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
filed_petitions_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"filed_petitions",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// financial_statement => /financial_statements/:id(.:format)
  // function(id, options)
  financial_statement_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financial_statements",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
financial_statement_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financial_statements",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// financial_statements => /financial_statements(.:format)
  // function(options)
  financial_statements_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financial_statements",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
financial_statements_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financial_statements",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// glimpse_intake_section_step_item => /intakes/:intake_id/sections/:section_name/steps/:step_name/items/:id/glimpse(.:format)
  // function(intake_id, section_name, step_name, id, options)
  glimpse_intake_section_step_item_path: Utils.route([["intake_id",true],["section_name",true],["step_name",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"intake_id",false],[2,[7,"/",false],[2,[6,"sections",false],[2,[7,"/",false],[2,[3,"section_name",false],[2,[7,"/",false],[2,[6,"steps",false],[2,[7,"/",false],[2,[3,"step_name",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"glimpse",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]]),
glimpse_intake_section_step_item_url: Utils.route([["intake_id",true],["section_name",true],["step_name",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"intake_id",false],[2,[7,"/",false],[2,[6,"sections",false],[2,[7,"/",false],[2,[3,"section_name",false],[2,[7,"/",false],[2,[6,"steps",false],[2,[7,"/",false],[2,[3,"step_name",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"glimpse",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]], true),
// health => /health(.:format)
  // function(options)
  health_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"health",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
health_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"health",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// hello => /hello(.:format)
  // function(options)
  hello_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"hello",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
hello_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"hello",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// home => /home(.:format)
  // function(options)
  home_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"home",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
home_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"home",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// i20 => /i20s/:id(.:format)
  // function(id, options)
  i20_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"i20s",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
i20_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"i20s",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// i20s => /i20s(.:format)
  // function(options)
  i20s_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"i20s",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
i20s_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"i20s",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// installment_options => /installment_options(.:format)
  // function(options)
  installment_options_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"installment_options",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
installment_options_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"installment_options",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// intake => /intakes/:id(.:format)
  // function(id, options)
  intake_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
intake_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// intake_instruction => /intakes/:intake_id/instructions/:id(.:format)
  // function(intake_id, id, options)
  intake_instruction_path: Utils.route([["intake_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"intake_id",false],[2,[7,"/",false],[2,[6,"instructions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
intake_instruction_url: Utils.route([["intake_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"intake_id",false],[2,[7,"/",false],[2,[6,"instructions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// intake_instructions => /intakes/:intake_id/instructions(.:format)
  // function(intake_id, options)
  intake_instructions_path: Utils.route([["intake_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"intake_id",false],[2,[7,"/",false],[2,[6,"instructions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
intake_instructions_url: Utils.route([["intake_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"intake_id",false],[2,[7,"/",false],[2,[6,"instructions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// intake_report_petitions => /petitions/intake_report(.:format)
  // function(options)
  intake_report_petitions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[6,"intake_report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
intake_report_petitions_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[6,"intake_report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// intake_section => /intakes/:intake_id/sections/:name(.:format)
  // function(intake_id, name, options)
  intake_section_path: Utils.route([["intake_id",true],["name",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"intake_id",false],[2,[7,"/",false],[2,[6,"sections",false],[2,[7,"/",false],[2,[3,"name",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
intake_section_url: Utils.route([["intake_id",true],["name",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"intake_id",false],[2,[7,"/",false],[2,[6,"sections",false],[2,[7,"/",false],[2,[3,"name",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// intake_section_step => /intakes/:intake_id/sections/:section_name/steps/:name(.:format)
  // function(intake_id, section_name, name, options)
  intake_section_step_path: Utils.route([["intake_id",true],["section_name",true],["name",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"intake_id",false],[2,[7,"/",false],[2,[6,"sections",false],[2,[7,"/",false],[2,[3,"section_name",false],[2,[7,"/",false],[2,[6,"steps",false],[2,[7,"/",false],[2,[3,"name",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
intake_section_step_url: Utils.route([["intake_id",true],["section_name",true],["name",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"intake_id",false],[2,[7,"/",false],[2,[6,"sections",false],[2,[7,"/",false],[2,[3,"section_name",false],[2,[7,"/",false],[2,[6,"steps",false],[2,[7,"/",false],[2,[3,"name",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], true),
// intake_section_step_item => /intakes/:intake_id/sections/:section_name/steps/:step_name/items/:id(.:format)
  // function(intake_id, section_name, step_name, id, options)
  intake_section_step_item_path: Utils.route([["intake_id",true],["section_name",true],["step_name",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"intake_id",false],[2,[7,"/",false],[2,[6,"sections",false],[2,[7,"/",false],[2,[3,"section_name",false],[2,[7,"/",false],[2,[6,"steps",false],[2,[7,"/",false],[2,[3,"step_name",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
intake_section_step_item_url: Utils.route([["intake_id",true],["section_name",true],["step_name",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"intake_id",false],[2,[7,"/",false],[2,[6,"sections",false],[2,[7,"/",false],[2,[3,"section_name",false],[2,[7,"/",false],[2,[6,"steps",false],[2,[7,"/",false],[2,[3,"step_name",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]], true),
// intake_section_step_items => /intakes/:intake_id/sections/:section_name/steps/:step_name/items(.:format)
  // function(intake_id, section_name, step_name, options)
  intake_section_step_items_path: Utils.route([["intake_id",true],["section_name",true],["step_name",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"intake_id",false],[2,[7,"/",false],[2,[6,"sections",false],[2,[7,"/",false],[2,[3,"section_name",false],[2,[7,"/",false],[2,[6,"steps",false],[2,[7,"/",false],[2,[3,"step_name",false],[2,[7,"/",false],[2,[6,"items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
intake_section_step_items_url: Utils.route([["intake_id",true],["section_name",true],["step_name",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"intake_id",false],[2,[7,"/",false],[2,[6,"sections",false],[2,[7,"/",false],[2,[3,"section_name",false],[2,[7,"/",false],[2,[6,"steps",false],[2,[7,"/",false],[2,[3,"step_name",false],[2,[7,"/",false],[2,[6,"items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]], true),
// intake_section_steps => /intakes/:intake_id/sections/:section_name/steps(.:format)
  // function(intake_id, section_name, options)
  intake_section_steps_path: Utils.route([["intake_id",true],["section_name",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"intake_id",false],[2,[7,"/",false],[2,[6,"sections",false],[2,[7,"/",false],[2,[3,"section_name",false],[2,[7,"/",false],[2,[6,"steps",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
intake_section_steps_url: Utils.route([["intake_id",true],["section_name",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"intake_id",false],[2,[7,"/",false],[2,[6,"sections",false],[2,[7,"/",false],[2,[3,"section_name",false],[2,[7,"/",false],[2,[6,"steps",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// intake_sections => /intakes/:intake_id/sections(.:format)
  // function(intake_id, options)
  intake_sections_path: Utils.route([["intake_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"intake_id",false],[2,[7,"/",false],[2,[6,"sections",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
intake_sections_url: Utils.route([["intake_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"intake_id",false],[2,[7,"/",false],[2,[6,"sections",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// intakes => /intakes(.:format)
  // function(options)
  intakes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
intakes_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// judging_event => /judging_events/:id(.:format)
  // function(id, options)
  judging_event_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"judging_events",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
judging_event_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"judging_events",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// judging_events => /judging_events(.:format)
  // function(options)
  judging_events_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"judging_events",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
judging_events_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"judging_events",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// lca => /lcas/:id(.:format)
  // function(id, options)
  lca_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lcas",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
lca_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lcas",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// lcas => /lcas(.:format)
  // function(options)
  lcas_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lcas",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
lcas_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lcas",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// magic_login => /magic_logins/:id(.:format)
  // function(id, options)
  magic_login_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"magic_logins",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
magic_login_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"magic_logins",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// make_primary_foreign_person_passport => /people/:person_id/passports/:id/make_primary_foreign(.:format)
  // function(person_id, id, options)
  make_primary_foreign_person_passport_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"passports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"make_primary_foreign",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
make_primary_foreign_person_passport_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"passports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"make_primary_foreign",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// make_primary_organization_address => /organizations/:organization_id/addresses/:id/make_primary(.:format)
  // function(organization_id, id, options)
  make_primary_organization_address_path: Utils.route([["organization_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"addresses",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"make_primary",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
make_primary_organization_address_url: Utils.route([["organization_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"addresses",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"make_primary",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// make_primary_person_address => /people/:person_id/addresses/:id/make_primary(.:format)
  // function(person_id, id, options)
  make_primary_person_address_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"addresses",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"make_primary",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
make_primary_person_address_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"addresses",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"make_primary",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// make_primary_usa_person_passport => /people/:person_id/passports/:id/make_primary_usa(.:format)
  // function(person_id, id, options)
  make_primary_usa_person_passport_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"passports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"make_primary_usa",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
make_primary_usa_person_passport_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"passports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"make_primary_usa",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// mapping => /mappings/:id(.:format)
  // function(id, options)
  mapping_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"mappings",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
mapping_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"mappings",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// mappings => /mappings(.:format)
  // function(options)
  mappings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"mappings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
mappings_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"mappings",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// metadata_intake_section_step_items => /intakes/:intake_id/sections/:section_name/steps/:step_name/items/metadata(.:format)
  // function(intake_id, section_name, step_name, options)
  metadata_intake_section_step_items_path: Utils.route([["intake_id",true],["section_name",true],["step_name",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"intake_id",false],[2,[7,"/",false],[2,[6,"sections",false],[2,[7,"/",false],[2,[3,"section_name",false],[2,[7,"/",false],[2,[6,"steps",false],[2,[7,"/",false],[2,[3,"step_name",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[6,"metadata",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
metadata_intake_section_step_items_url: Utils.route([["intake_id",true],["section_name",true],["step_name",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"intake_id",false],[2,[7,"/",false],[2,[6,"sections",false],[2,[7,"/",false],[2,[3,"section_name",false],[2,[7,"/",false],[2,[6,"steps",false],[2,[7,"/",false],[2,[3,"step_name",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[6,"metadata",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]], true),
// new_address => /addresses/new(.:format)
  // function(options)
  new_address_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"addresses",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_address_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"addresses",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_alias => /aliases/new(.:format)
  // function(options)
  new_alias_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"aliases",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_alias_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"aliases",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_approval_notice => /approval_notices/new(.:format)
  // function(options)
  new_approval_notice_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"approval_notices",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_approval_notice_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"approval_notices",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_area => /areas/new(.:format)
  // function(options)
  new_area_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"areas",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_area_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"areas",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_bank_statement => /bank_statements/new(.:format)
  // function(options)
  new_bank_statement_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"bank_statements",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_bank_statement_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"bank_statements",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_client => /clients/new(.:format)
  // function(options)
  new_client_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_client_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_dataset => /datasets/new(.:format)
  // function(options)
  new_dataset_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"datasets",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_dataset_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"datasets",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_degree => /degrees/new(.:format)
  // function(options)
  new_degree_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"degrees",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_degree_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"degrees",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_denied_visa => /denied_visas/new(.:format)
  // function(options)
  new_denied_visa_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"denied_visas",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_denied_visa_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"denied_visas",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_dependent => /dependents/new(.:format)
  // function(options)
  new_dependent_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dependents",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_dependent_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dependents",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_ds2019 => /ds2019s/new(.:format)
  // function(options)
  new_ds2019_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ds2019s",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_ds2019_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ds2019s",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_education_evaluation => /education_evaluations/new(.:format)
  // function(options)
  new_education_evaluation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"education_evaluations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_education_evaluation_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"education_evaluations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_email_address => /email_addresses/new(.:format)
  // function(options)
  new_email_address_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"email_addresses",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_email_address_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"email_addresses",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_employee => /employees/new(.:format)
  // function(options)
  new_employee_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_employee_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_employment_authorization => /employment_authorizations/new(.:format)
  // function(options)
  new_employment_authorization_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"employment_authorizations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_employment_authorization_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"employment_authorizations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_entry_stamp => /entry_stamps/new(.:format)
  // function(options)
  new_entry_stamp_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"entry_stamps",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_entry_stamp_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"entry_stamps",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_filed_petition => /filed_petitions/new(.:format)
  // function(options)
  new_filed_petition_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"filed_petitions",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_filed_petition_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"filed_petitions",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_financial_statement => /financial_statements/new(.:format)
  // function(options)
  new_financial_statement_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financial_statements",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_financial_statement_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financial_statements",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_i20 => /i20s/new(.:format)
  // function(options)
  new_i20_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"i20s",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_i20_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"i20s",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_intake => /intakes/new(.:format)
  // function(options)
  new_intake_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_intake_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_intake_instruction => /intakes/:intake_id/instructions/new(.:format)
  // function(intake_id, options)
  new_intake_instruction_path: Utils.route([["intake_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"intake_id",false],[2,[7,"/",false],[2,[6,"instructions",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_intake_instruction_url: Utils.route([["intake_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"intake_id",false],[2,[7,"/",false],[2,[6,"instructions",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_intake_section => /intakes/:intake_id/sections/new(.:format)
  // function(intake_id, options)
  new_intake_section_path: Utils.route([["intake_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"intake_id",false],[2,[7,"/",false],[2,[6,"sections",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_intake_section_url: Utils.route([["intake_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"intake_id",false],[2,[7,"/",false],[2,[6,"sections",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_intake_section_step => /intakes/:intake_id/sections/:section_name/steps/new(.:format)
  // function(intake_id, section_name, options)
  new_intake_section_step_path: Utils.route([["intake_id",true],["section_name",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"intake_id",false],[2,[7,"/",false],[2,[6,"sections",false],[2,[7,"/",false],[2,[3,"section_name",false],[2,[7,"/",false],[2,[6,"steps",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
new_intake_section_step_url: Utils.route([["intake_id",true],["section_name",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"intake_id",false],[2,[7,"/",false],[2,[6,"sections",false],[2,[7,"/",false],[2,[3,"section_name",false],[2,[7,"/",false],[2,[6,"steps",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], true),
// new_intake_section_step_item => /intakes/:intake_id/sections/:section_name/steps/:step_name/items/new(.:format)
  // function(intake_id, section_name, step_name, options)
  new_intake_section_step_item_path: Utils.route([["intake_id",true],["section_name",true],["step_name",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"intake_id",false],[2,[7,"/",false],[2,[6,"sections",false],[2,[7,"/",false],[2,[3,"section_name",false],[2,[7,"/",false],[2,[6,"steps",false],[2,[7,"/",false],[2,[3,"step_name",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
new_intake_section_step_item_url: Utils.route([["intake_id",true],["section_name",true],["step_name",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"intake_id",false],[2,[7,"/",false],[2,[6,"sections",false],[2,[7,"/",false],[2,[3,"section_name",false],[2,[7,"/",false],[2,[6,"steps",false],[2,[7,"/",false],[2,[3,"step_name",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]], true),
// new_judging_event => /judging_events/new(.:format)
  // function(options)
  new_judging_event_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"judging_events",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_judging_event_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"judging_events",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_lca => /lcas/new(.:format)
  // function(options)
  new_lca_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lcas",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_lca_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lcas",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_mapping => /mappings/new(.:format)
  // function(options)
  new_mapping_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"mappings",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_mapping_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"mappings",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_occupation => /occupations/new(.:format)
  // function(options)
  new_occupation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"occupations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_occupation_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"occupations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_organization => /organizations/new(.:format)
  // function(options)
  new_organization_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_organization_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_organization_address => /organizations/:organization_id/addresses/new(.:format)
  // function(organization_id, options)
  new_organization_address_path: Utils.route([["organization_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"addresses",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_organization_address_url: Utils.route([["organization_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"addresses",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_organization_affiliation => /organizations/:organization_id/affiliations/new(.:format)
  // function(organization_id, options)
  new_organization_affiliation_path: Utils.route([["organization_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"affiliations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_organization_affiliation_url: Utils.route([["organization_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"affiliations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_organization_bank_statement => /organizations/:organization_id/bank_statements/new(.:format)
  // function(organization_id, options)
  new_organization_bank_statement_path: Utils.route([["organization_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"bank_statements",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_organization_bank_statement_url: Utils.route([["organization_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"bank_statements",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_organization_contact_detail => /organizations/:organization_id/contact_detail/new(.:format)
  // function(organization_id, options)
  new_organization_contact_detail_path: Utils.route([["organization_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"contact_detail",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_organization_contact_detail_url: Utils.route([["organization_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"contact_detail",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_organization_financial_statement => /organizations/:organization_id/financial_statements/new(.:format)
  // function(organization_id, options)
  new_organization_financial_statement_path: Utils.route([["organization_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"financial_statements",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_organization_financial_statement_url: Utils.route([["organization_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"financial_statements",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_organization_history => /organizations/:organization_id/history/new(.:format)
  // function(organization_id, options)
  new_organization_history_path: Utils.route([["organization_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"history",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_organization_history_url: Utils.route([["organization_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"history",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_organization_payroll_record => /organizations/:organization_id/payroll_records/new(.:format)
  // function(organization_id, options)
  new_organization_payroll_record_path: Utils.route([["organization_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"payroll_records",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_organization_payroll_record_url: Utils.route([["organization_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"payroll_records",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_organization_person => /organizations/:organization_id/person/new(.:format)
  // function(organization_id, options)
  new_organization_person_path: Utils.route([["organization_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"person",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_organization_person_url: Utils.route([["organization_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"person",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_organization_press => /organizations/:organization_id/press/new(.:format)
  // function(organization_id, options)
  new_organization_press_path: Utils.route([["organization_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"press",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_organization_press_url: Utils.route([["organization_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"press",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_organization_profile => /organizations/:organization_id/profile/new(.:format)
  // function(organization_id, options)
  new_organization_profile_path: Utils.route([["organization_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"profile",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_organization_profile_url: Utils.route([["organization_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"profile",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_passport => /passports/new(.:format)
  // function(options)
  new_passport_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"passports",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_passport_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"passports",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_pay_stub => /pay_stubs/new(.:format)
  // function(options)
  new_pay_stub_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"pay_stubs",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_pay_stub_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"pay_stubs",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_person => /people/new(.:format)
  // function(options)
  new_person_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_person_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_person_accelerator => /people/:person_id/accelerators/new(.:format)
  // function(person_id, options)
  new_person_accelerator_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"accelerators",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_person_accelerator_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"accelerators",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_person_address => /people/:person_id/addresses/new(.:format)
  // function(person_id, options)
  new_person_address_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"addresses",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_person_address_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"addresses",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_person_alias => /people/:person_id/aliases/new(.:format)
  // function(person_id, options)
  new_person_alias_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"aliases",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_person_alias_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"aliases",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_person_approval_notice => /people/:person_id/approval_notices/new(.:format)
  // function(person_id, options)
  new_person_approval_notice_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"approval_notices",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_person_approval_notice_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"approval_notices",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_person_association => /people/:person_id/associations/new(.:format)
  // function(person_id, options)
  new_person_association_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"associations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_person_association_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"associations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_person_attorney => /people/:person_id/attorney/new(.:format)
  // function(person_id, options)
  new_person_attorney_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"attorney",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_person_attorney_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"attorney",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_person_award => /people/:person_id/awards/new(.:format)
  // function(person_id, options)
  new_person_award_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"awards",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_person_award_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"awards",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_person_beneficiary => /people/:person_id/beneficiary/new(.:format)
  // function(person_id, options)
  new_person_beneficiary_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"beneficiary",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_person_beneficiary_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"beneficiary",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_person_contact_detail => /people/:person_id/contact_detail/new(.:format)
  // function(person_id, options)
  new_person_contact_detail_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"contact_detail",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_person_contact_detail_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"contact_detail",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_person_critical_employment => /people/:person_id/critical_employments/new(.:format)
  // function(person_id, options)
  new_person_critical_employment_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"critical_employments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_person_critical_employment_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"critical_employments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_person_degree => /people/:person_id/degrees/new(.:format)
  // function(person_id, options)
  new_person_degree_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"degrees",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_person_degree_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"degrees",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_person_denied_visa => /people/:person_id/denied_visas/new(.:format)
  // function(person_id, options)
  new_person_denied_visa_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"denied_visas",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_person_denied_visa_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"denied_visas",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_person_dependent => /people/:person_id/dependents/new(.:format)
  // function(person_id, options)
  new_person_dependent_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"dependents",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_person_dependent_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"dependents",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_person_ds2019 => /people/:person_id/ds2019s/new(.:format)
  // function(person_id, options)
  new_person_ds2019_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"ds2019s",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_person_ds2019_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"ds2019s",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_person_education_evaluation => /people/:person_id/education_evaluations/new(.:format)
  // function(person_id, options)
  new_person_education_evaluation_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"education_evaluations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_person_education_evaluation_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"education_evaluations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_person_employment => /people/:person_id/employment/new(.:format)
  // function(person_id, options)
  new_person_employment_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"employment",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_person_employment_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"employment",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_person_employment_authorization => /people/:person_id/employment_authorizations/new(.:format)
  // function(person_id, options)
  new_person_employment_authorization_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"employment_authorizations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_person_employment_authorization_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"employment_authorizations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_person_entry_stamp => /people/:person_id/entry_stamps/new(.:format)
  // function(person_id, options)
  new_person_entry_stamp_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"entry_stamps",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_person_entry_stamp_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"entry_stamps",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_person_filed_petition => /people/:person_id/filed_petitions/new(.:format)
  // function(person_id, options)
  new_person_filed_petition_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"filed_petitions",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_person_filed_petition_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"filed_petitions",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_person_high_remuneration_employment => /people/:person_id/high_remuneration_employments/new(.:format)
  // function(person_id, options)
  new_person_high_remuneration_employment_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"high_remuneration_employments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_person_high_remuneration_employment_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"high_remuneration_employments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_person_history => /people/:person_id/history/new(.:format)
  // function(person_id, options)
  new_person_history_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"history",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_person_history_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"history",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_person_i20 => /people/:person_id/i20s/new(.:format)
  // function(person_id, options)
  new_person_i20_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"i20s",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_person_i20_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"i20s",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_person_judging_event => /people/:person_id/judging_events/new(.:format)
  // function(person_id, options)
  new_person_judging_event_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"judging_events",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_person_judging_event_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"judging_events",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_person_overview => /people/:person_id/overview/new(.:format)
  // function(person_id, options)
  new_person_overview_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"overview",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_person_overview_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"overview",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_person_passport => /people/:person_id/passports/new(.:format)
  // function(person_id, options)
  new_person_passport_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"passports",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_person_passport_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"passports",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_person_patent => /people/:person_id/patents/new(.:format)
  // function(person_id, options)
  new_person_patent_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"patents",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_person_patent_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"patents",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_person_pay_stub => /people/:person_id/pay_stubs/new(.:format)
  // function(person_id, options)
  new_person_pay_stub_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"pay_stubs",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_person_pay_stub_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"pay_stubs",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_person_press => /people/:person_id/press/new(.:format)
  // function(person_id, options)
  new_person_press_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"press",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_person_press_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"press",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_person_proof_of_residency => /people/:person_id/proof_of_residencies/new(.:format)
  // function(person_id, options)
  new_person_proof_of_residency_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"proof_of_residencies",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_person_proof_of_residency_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"proof_of_residencies",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_person_scholarly_article => /people/:person_id/scholarly_articles/new(.:format)
  // function(person_id, options)
  new_person_scholarly_article_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"scholarly_articles",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_person_scholarly_article_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"scholarly_articles",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_person_stay => /people/:person_id/stays/new(.:format)
  // function(person_id, options)
  new_person_stay_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"stays",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_person_stay_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"stays",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_person_venture_funding => /people/:person_id/venture_fundings/new(.:format)
  // function(person_id, options)
  new_person_venture_funding_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"venture_fundings",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_person_venture_funding_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"venture_fundings",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_person_venture_funding_funding_document => /people/:person_id/venture_fundings/:venture_funding_id/funding_documents/new(.:format)
  // function(person_id, venture_funding_id, options)
  new_person_venture_funding_funding_document_path: Utils.route([["person_id",true],["venture_funding_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"venture_fundings",false],[2,[7,"/",false],[2,[3,"venture_funding_id",false],[2,[7,"/",false],[2,[6,"funding_documents",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
new_person_venture_funding_funding_document_url: Utils.route([["person_id",true],["venture_funding_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"venture_fundings",false],[2,[7,"/",false],[2,[3,"venture_funding_id",false],[2,[7,"/",false],[2,[6,"funding_documents",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], true),
// new_person_visa_status => /people/:person_id/visa_statuses/new(.:format)
  // function(person_id, options)
  new_person_visa_status_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"visa_statuses",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_person_visa_status_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"visa_statuses",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_petition => /petitions/new(.:format)
  // function(options)
  new_petition_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_petition_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_petition_advisory_opinion => /petitions/:petition_id/advisory_opinion/new(.:format)
  // function(petition_id, options)
  new_petition_advisory_opinion_path: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"advisory_opinion",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_petition_advisory_opinion_url: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"advisory_opinion",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_petition_attorney => /petitions/:petition_id/attorney/new(.:format)
  // function(petition_id, options)
  new_petition_attorney_path: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"attorney",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_petition_attorney_url: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"attorney",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_petition_attribute => /petitions/:petition_id/attributes/new(.:format)
  // function(petition_id, options)
  new_petition_attribute_path: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"attributes",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_petition_attribute_url: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"attributes",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_petition_beneficiary => /petitions/:petition_id/beneficiary/new(.:format)
  // function(petition_id, options)
  new_petition_beneficiary_path: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"beneficiary",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_petition_beneficiary_url: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"beneficiary",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_petition_case_status => /petitions/:petition_id/case_status/new(.:format)
  // function(petition_id, options)
  new_petition_case_status_path: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"case_status",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_petition_case_status_url: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"case_status",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_petition_contact => /petitions/:petition_id/contacts/new(.:format)
  // function(petition_id, options)
  new_petition_contact_path: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_petition_contact_url: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_petition_event => /petitions/:petition_id/events/new(.:format)
  // function(petition_id, options)
  new_petition_event_path: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"events",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_petition_event_url: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"events",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_petition_evidence_request => /petitions/:petition_id/evidence_request/new(.:format)
  // function(petition_id, options)
  new_petition_evidence_request_path: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"evidence_request",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_petition_evidence_request_url: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"evidence_request",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_petition_history => /petitions/:petition_id/history/new(.:format)
  // function(petition_id, options)
  new_petition_history_path: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"history",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_petition_history_url: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"history",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_petition_mailer => /petitions/:petition_id/mailers/new(.:format)
  // function(petition_id, options)
  new_petition_mailer_path: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"mailers",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_petition_mailer_url: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"mailers",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_petition_offer => /petitions/:petition_id/offer/new(.:format)
  // function(petition_id, options)
  new_petition_offer_path: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"offer",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_petition_offer_url: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"offer",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_petition_overview => /petitions/:petition_id/overview/new(.:format)
  // function(petition_id, options)
  new_petition_overview_path: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"overview",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_petition_overview_url: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"overview",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_petition_representative => /petitions/:petition_id/representative/new(.:format)
  // function(petition_id, options)
  new_petition_representative_path: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"representative",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_petition_representative_url: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"representative",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_petition_signatory => /petitions/:petition_id/signatory/new(.:format)
  // function(petition_id, options)
  new_petition_signatory_path: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"signatory",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_petition_signatory_url: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"signatory",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_press => /press/new(.:format)
  // function(options)
  new_press_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"press",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_press_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"press",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_proof_of_residency => /proof_of_residencies/new(.:format)
  // function(options)
  new_proof_of_residency_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"proof_of_residencies",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_proof_of_residency_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"proof_of_residencies",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_schema => /schema/new(.:format)
  // function(options)
  new_schema_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"schema",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_schema_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"schema",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_setting => /settings/new(.:format)
  // function(options)
  new_setting_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_setting_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_shipment => /shipments/new(.:format)
  // function(options)
  new_shipment_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"shipments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_shipment_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"shipments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_stay => /stays/new(.:format)
  // function(options)
  new_stay_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"stays",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_stay_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"stays",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_user => /users/new(.:format)
  // function(options)
  new_user_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_user_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_user_confirmation => /users/confirmation/new(.:format)
  // function(options)
  new_user_confirmation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"confirmation",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
new_user_confirmation_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"confirmation",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// new_user_password => /users/password/new(.:format)
  // function(options)
  new_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
new_user_password_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// new_user_registration => /users/sign_up(.:format)
  // function(options)
  new_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_up",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_user_registration_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_up",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_user_session => /users/sign_in(.:format)
  // function(options)
  new_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_user_session_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_visa_status => /visa_statuses/new(.:format)
  // function(options)
  new_visa_status_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"visa_statuses",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_visa_status_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"visa_statuses",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_workflow => /workflows/new(.:format)
  // function(options)
  new_workflow_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"workflows",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_workflow_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"workflows",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_workflow_history => /workflows/:workflow_id/history/new(.:format)
  // function(workflow_id, options)
  new_workflow_history_path: Utils.route([["workflow_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"workflows",false],[2,[7,"/",false],[2,[3,"workflow_id",false],[2,[7,"/",false],[2,[6,"history",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_workflow_history_url: Utils.route([["workflow_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"workflows",false],[2,[7,"/",false],[2,[3,"workflow_id",false],[2,[7,"/",false],[2,[6,"history",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_worksite => /worksites/new(.:format)
  // function(options)
  new_worksite_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"worksites",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_worksite_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"worksites",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// note => /notes/:id(.:format)
  // function(id, options)
  note_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"notes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
note_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"notes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// notes => /notes(.:format)
  // function(options)
  notes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"notes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
notes_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"notes",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// occupation => /occupations/:id(.:format)
  // function(id, options)
  occupation_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"occupations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
occupation_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"occupations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// occupations => /occupations(.:format)
  // function(options)
  occupations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"occupations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
occupations_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"occupations",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// organization => /organizations/:id(.:format)
  // function(id, options)
  organization_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
organization_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// organization_address => /organizations/:organization_id/addresses/:id(.:format)
  // function(organization_id, id, options)
  organization_address_path: Utils.route([["organization_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"addresses",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
organization_address_url: Utils.route([["organization_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"addresses",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// organization_addresses => /organizations/:organization_id/addresses(.:format)
  // function(organization_id, options)
  organization_addresses_path: Utils.route([["organization_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"addresses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
organization_addresses_url: Utils.route([["organization_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"addresses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// organization_affiliation => /organizations/:organization_id/affiliations/:id(.:format)
  // function(organization_id, id, options)
  organization_affiliation_path: Utils.route([["organization_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"affiliations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
organization_affiliation_url: Utils.route([["organization_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"affiliations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// organization_affiliations => /organizations/:organization_id/affiliations(.:format)
  // function(organization_id, options)
  organization_affiliations_path: Utils.route([["organization_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"affiliations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
organization_affiliations_url: Utils.route([["organization_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"affiliations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// organization_bank_statement => /organizations/:organization_id/bank_statements/:id(.:format)
  // function(organization_id, id, options)
  organization_bank_statement_path: Utils.route([["organization_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"bank_statements",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
organization_bank_statement_url: Utils.route([["organization_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"bank_statements",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// organization_bank_statements => /organizations/:organization_id/bank_statements(.:format)
  // function(organization_id, options)
  organization_bank_statements_path: Utils.route([["organization_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"bank_statements",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
organization_bank_statements_url: Utils.route([["organization_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"bank_statements",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// organization_contact_detail => /organizations/:organization_id/contact_detail(.:format)
  // function(organization_id, options)
  organization_contact_detail_path: Utils.route([["organization_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"contact_detail",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
organization_contact_detail_url: Utils.route([["organization_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"contact_detail",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// organization_financial_statement => /organizations/:organization_id/financial_statements/:id(.:format)
  // function(organization_id, id, options)
  organization_financial_statement_path: Utils.route([["organization_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"financial_statements",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
organization_financial_statement_url: Utils.route([["organization_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"financial_statements",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// organization_financial_statements => /organizations/:organization_id/financial_statements(.:format)
  // function(organization_id, options)
  organization_financial_statements_path: Utils.route([["organization_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"financial_statements",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
organization_financial_statements_url: Utils.route([["organization_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"financial_statements",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// organization_history => /organizations/:organization_id/history(.:format)
  // function(organization_id, options)
  organization_history_path: Utils.route([["organization_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"history",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
organization_history_url: Utils.route([["organization_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"history",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// organization_payroll_record => /organizations/:organization_id/payroll_records/:id(.:format)
  // function(organization_id, id, options)
  organization_payroll_record_path: Utils.route([["organization_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"payroll_records",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
organization_payroll_record_url: Utils.route([["organization_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"payroll_records",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// organization_payroll_records => /organizations/:organization_id/payroll_records(.:format)
  // function(organization_id, options)
  organization_payroll_records_path: Utils.route([["organization_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"payroll_records",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
organization_payroll_records_url: Utils.route([["organization_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"payroll_records",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// organization_person => /organizations/:organization_id/person(.:format)
  // function(organization_id, options)
  organization_person_path: Utils.route([["organization_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"person",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
organization_person_url: Utils.route([["organization_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"person",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// organization_press => /organizations/:organization_id/press/:id(.:format)
  // function(organization_id, id, options)
  organization_press_path: Utils.route([["organization_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"press",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
organization_press_url: Utils.route([["organization_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"press",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// organization_press_index => /organizations/:organization_id/press(.:format)
  // function(organization_id, options)
  organization_press_index_path: Utils.route([["organization_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"press",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
organization_press_index_url: Utils.route([["organization_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"press",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// organization_profile => /organizations/:organization_id/profile(.:format)
  // function(organization_id, options)
  organization_profile_path: Utils.route([["organization_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"profile",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
organization_profile_url: Utils.route([["organization_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"profile",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// organizations => /organizations(.:format)
  // function(options)
  organizations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
organizations_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// passport => /passports/:id(.:format)
  // function(id, options)
  passport_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"passports",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
passport_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"passports",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// passports => /passports(.:format)
  // function(options)
  passports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"passports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
passports_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"passports",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// pay_stub => /pay_stubs/:id(.:format)
  // function(id, options)
  pay_stub_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"pay_stubs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
pay_stub_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"pay_stubs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// pay_stubs => /pay_stubs(.:format)
  // function(options)
  pay_stubs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"pay_stubs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
pay_stubs_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"pay_stubs",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// people => /people(.:format)
  // function(options)
  people_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
people_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// people_organization => /organizations/:id/people(.:format)
  // function(id, options)
  people_organization_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"people",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
people_organization_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"people",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// person => /people/:id(.:format)
  // function(id, options)
  person_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
person_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// person_accelerator => /people/:person_id/accelerators/:id(.:format)
  // function(person_id, id, options)
  person_accelerator_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"accelerators",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
person_accelerator_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"accelerators",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// person_accelerators => /people/:person_id/accelerators(.:format)
  // function(person_id, options)
  person_accelerators_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"accelerators",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
person_accelerators_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"accelerators",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// person_address => /people/:person_id/addresses/:id(.:format)
  // function(person_id, id, options)
  person_address_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"addresses",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
person_address_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"addresses",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// person_addresses => /people/:person_id/addresses(.:format)
  // function(person_id, options)
  person_addresses_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"addresses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
person_addresses_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"addresses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// person_alias => /people/:person_id/aliases/:id(.:format)
  // function(person_id, id, options)
  person_alias_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"aliases",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
person_alias_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"aliases",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// person_aliases => /people/:person_id/aliases(.:format)
  // function(person_id, options)
  person_aliases_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"aliases",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
person_aliases_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"aliases",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// person_approval_notice => /people/:person_id/approval_notices/:id(.:format)
  // function(person_id, id, options)
  person_approval_notice_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"approval_notices",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
person_approval_notice_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"approval_notices",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// person_approval_notices => /people/:person_id/approval_notices(.:format)
  // function(person_id, options)
  person_approval_notices_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"approval_notices",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
person_approval_notices_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"approval_notices",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// person_association => /people/:person_id/associations/:id(.:format)
  // function(person_id, id, options)
  person_association_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"associations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
person_association_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"associations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// person_associations => /people/:person_id/associations(.:format)
  // function(person_id, options)
  person_associations_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"associations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
person_associations_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"associations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// person_attorney => /people/:person_id/attorney(.:format)
  // function(person_id, options)
  person_attorney_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"attorney",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
person_attorney_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"attorney",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// person_award => /people/:person_id/awards/:id(.:format)
  // function(person_id, id, options)
  person_award_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"awards",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
person_award_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"awards",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// person_awards => /people/:person_id/awards(.:format)
  // function(person_id, options)
  person_awards_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"awards",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
person_awards_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"awards",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// person_beneficiary => /people/:person_id/beneficiary(.:format)
  // function(person_id, options)
  person_beneficiary_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"beneficiary",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
person_beneficiary_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"beneficiary",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// person_contact_detail => /people/:person_id/contact_detail(.:format)
  // function(person_id, options)
  person_contact_detail_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"contact_detail",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
person_contact_detail_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"contact_detail",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// person_critical_employment => /people/:person_id/critical_employments/:id(.:format)
  // function(person_id, id, options)
  person_critical_employment_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"critical_employments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
person_critical_employment_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"critical_employments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// person_critical_employments => /people/:person_id/critical_employments(.:format)
  // function(person_id, options)
  person_critical_employments_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"critical_employments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
person_critical_employments_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"critical_employments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// person_degree => /people/:person_id/degrees/:id(.:format)
  // function(person_id, id, options)
  person_degree_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"degrees",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
person_degree_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"degrees",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// person_degrees => /people/:person_id/degrees(.:format)
  // function(person_id, options)
  person_degrees_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"degrees",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
person_degrees_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"degrees",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// person_denied_visa => /people/:person_id/denied_visas/:id(.:format)
  // function(person_id, id, options)
  person_denied_visa_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"denied_visas",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
person_denied_visa_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"denied_visas",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// person_denied_visas => /people/:person_id/denied_visas(.:format)
  // function(person_id, options)
  person_denied_visas_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"denied_visas",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
person_denied_visas_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"denied_visas",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// person_dependent => /people/:person_id/dependents/:id(.:format)
  // function(person_id, id, options)
  person_dependent_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"dependents",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
person_dependent_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"dependents",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// person_dependents => /people/:person_id/dependents(.:format)
  // function(person_id, options)
  person_dependents_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"dependents",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
person_dependents_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"dependents",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// person_ds2019 => /people/:person_id/ds2019s/:id(.:format)
  // function(person_id, id, options)
  person_ds2019_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"ds2019s",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
person_ds2019_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"ds2019s",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// person_ds2019s => /people/:person_id/ds2019s(.:format)
  // function(person_id, options)
  person_ds2019s_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"ds2019s",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
person_ds2019s_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"ds2019s",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// person_education_evaluation => /people/:person_id/education_evaluations/:id(.:format)
  // function(person_id, id, options)
  person_education_evaluation_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"education_evaluations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
person_education_evaluation_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"education_evaluations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// person_education_evaluations => /people/:person_id/education_evaluations(.:format)
  // function(person_id, options)
  person_education_evaluations_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"education_evaluations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
person_education_evaluations_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"education_evaluations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// person_employment => /people/:person_id/employment(.:format)
  // function(person_id, options)
  person_employment_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"employment",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
person_employment_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"employment",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// person_employment_authorization => /people/:person_id/employment_authorizations/:id(.:format)
  // function(person_id, id, options)
  person_employment_authorization_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"employment_authorizations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
person_employment_authorization_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"employment_authorizations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// person_employment_authorizations => /people/:person_id/employment_authorizations(.:format)
  // function(person_id, options)
  person_employment_authorizations_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"employment_authorizations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
person_employment_authorizations_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"employment_authorizations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// person_entry_stamp => /people/:person_id/entry_stamps/:id(.:format)
  // function(person_id, id, options)
  person_entry_stamp_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"entry_stamps",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
person_entry_stamp_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"entry_stamps",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// person_entry_stamps => /people/:person_id/entry_stamps(.:format)
  // function(person_id, options)
  person_entry_stamps_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"entry_stamps",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
person_entry_stamps_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"entry_stamps",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// person_filed_petition => /people/:person_id/filed_petitions/:id(.:format)
  // function(person_id, id, options)
  person_filed_petition_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"filed_petitions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
person_filed_petition_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"filed_petitions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// person_filed_petitions => /people/:person_id/filed_petitions(.:format)
  // function(person_id, options)
  person_filed_petitions_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"filed_petitions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
person_filed_petitions_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"filed_petitions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// person_high_remuneration_employment => /people/:person_id/high_remuneration_employments/:id(.:format)
  // function(person_id, id, options)
  person_high_remuneration_employment_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"high_remuneration_employments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
person_high_remuneration_employment_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"high_remuneration_employments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// person_high_remuneration_employments => /people/:person_id/high_remuneration_employments(.:format)
  // function(person_id, options)
  person_high_remuneration_employments_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"high_remuneration_employments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
person_high_remuneration_employments_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"high_remuneration_employments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// person_history => /people/:person_id/history(.:format)
  // function(person_id, options)
  person_history_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"history",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
person_history_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"history",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// person_i20 => /people/:person_id/i20s/:id(.:format)
  // function(person_id, id, options)
  person_i20_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"i20s",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
person_i20_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"i20s",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// person_i20s => /people/:person_id/i20s(.:format)
  // function(person_id, options)
  person_i20s_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"i20s",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
person_i20s_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"i20s",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// person_judging_event => /people/:person_id/judging_events/:id(.:format)
  // function(person_id, id, options)
  person_judging_event_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"judging_events",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
person_judging_event_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"judging_events",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// person_judging_events => /people/:person_id/judging_events(.:format)
  // function(person_id, options)
  person_judging_events_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"judging_events",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
person_judging_events_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"judging_events",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// person_overview => /people/:person_id/overview(.:format)
  // function(person_id, options)
  person_overview_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"overview",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
person_overview_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"overview",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// person_passport => /people/:person_id/passports/:id(.:format)
  // function(person_id, id, options)
  person_passport_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"passports",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
person_passport_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"passports",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// person_passports => /people/:person_id/passports(.:format)
  // function(person_id, options)
  person_passports_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"passports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
person_passports_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"passports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// person_patent => /people/:person_id/patents/:id(.:format)
  // function(person_id, id, options)
  person_patent_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"patents",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
person_patent_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"patents",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// person_patents => /people/:person_id/patents(.:format)
  // function(person_id, options)
  person_patents_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"patents",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
person_patents_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"patents",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// person_pay_stub => /people/:person_id/pay_stubs/:id(.:format)
  // function(person_id, id, options)
  person_pay_stub_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"pay_stubs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
person_pay_stub_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"pay_stubs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// person_pay_stubs => /people/:person_id/pay_stubs(.:format)
  // function(person_id, options)
  person_pay_stubs_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"pay_stubs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
person_pay_stubs_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"pay_stubs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// person_press => /people/:person_id/press/:id(.:format)
  // function(person_id, id, options)
  person_press_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"press",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
person_press_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"press",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// person_press_index => /people/:person_id/press(.:format)
  // function(person_id, options)
  person_press_index_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"press",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
person_press_index_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"press",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// person_proof_of_residencies => /people/:person_id/proof_of_residencies(.:format)
  // function(person_id, options)
  person_proof_of_residencies_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"proof_of_residencies",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
person_proof_of_residencies_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"proof_of_residencies",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// person_proof_of_residency => /people/:person_id/proof_of_residencies/:id(.:format)
  // function(person_id, id, options)
  person_proof_of_residency_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"proof_of_residencies",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
person_proof_of_residency_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"proof_of_residencies",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// person_scholarly_article => /people/:person_id/scholarly_articles/:id(.:format)
  // function(person_id, id, options)
  person_scholarly_article_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"scholarly_articles",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
person_scholarly_article_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"scholarly_articles",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// person_scholarly_articles => /people/:person_id/scholarly_articles(.:format)
  // function(person_id, options)
  person_scholarly_articles_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"scholarly_articles",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
person_scholarly_articles_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"scholarly_articles",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// person_stay => /people/:person_id/stays/:id(.:format)
  // function(person_id, id, options)
  person_stay_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"stays",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
person_stay_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"stays",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// person_stays => /people/:person_id/stays(.:format)
  // function(person_id, options)
  person_stays_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"stays",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
person_stays_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"stays",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// person_venture_funding => /people/:person_id/venture_fundings/:id(.:format)
  // function(person_id, id, options)
  person_venture_funding_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"venture_fundings",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
person_venture_funding_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"venture_fundings",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// person_venture_funding_funding_document => /people/:person_id/venture_fundings/:venture_funding_id/funding_documents/:id(.:format)
  // function(person_id, venture_funding_id, id, options)
  person_venture_funding_funding_document_path: Utils.route([["person_id",true],["venture_funding_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"venture_fundings",false],[2,[7,"/",false],[2,[3,"venture_funding_id",false],[2,[7,"/",false],[2,[6,"funding_documents",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
person_venture_funding_funding_document_url: Utils.route([["person_id",true],["venture_funding_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"venture_fundings",false],[2,[7,"/",false],[2,[3,"venture_funding_id",false],[2,[7,"/",false],[2,[6,"funding_documents",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], true),
// person_venture_funding_funding_documents => /people/:person_id/venture_fundings/:venture_funding_id/funding_documents(.:format)
  // function(person_id, venture_funding_id, options)
  person_venture_funding_funding_documents_path: Utils.route([["person_id",true],["venture_funding_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"venture_fundings",false],[2,[7,"/",false],[2,[3,"venture_funding_id",false],[2,[7,"/",false],[2,[6,"funding_documents",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
person_venture_funding_funding_documents_url: Utils.route([["person_id",true],["venture_funding_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"venture_fundings",false],[2,[7,"/",false],[2,[3,"venture_funding_id",false],[2,[7,"/",false],[2,[6,"funding_documents",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// person_venture_fundings => /people/:person_id/venture_fundings(.:format)
  // function(person_id, options)
  person_venture_fundings_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"venture_fundings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
person_venture_fundings_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"venture_fundings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// person_visa_status => /people/:person_id/visa_statuses/:id(.:format)
  // function(person_id, id, options)
  person_visa_status_path: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"visa_statuses",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
person_visa_status_url: Utils.route([["person_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"visa_statuses",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// person_visa_statuses => /people/:person_id/visa_statuses(.:format)
  // function(person_id, options)
  person_visa_statuses_path: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"visa_statuses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
person_visa_statuses_url: Utils.route([["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"visa_statuses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// petition => /petitions/:id(.:format)
  // function(id, options)
  petition_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
petition_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// petition_advisory_opinion => /petitions/:petition_id/advisory_opinion(.:format)
  // function(petition_id, options)
  petition_advisory_opinion_path: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"advisory_opinion",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
petition_advisory_opinion_url: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"advisory_opinion",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// petition_attorney => /petitions/:petition_id/attorney(.:format)
  // function(petition_id, options)
  petition_attorney_path: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"attorney",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
petition_attorney_url: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"attorney",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// petition_attribute => /petitions/:petition_id/attributes/:id(.:format)
  // function(petition_id, id, options)
  petition_attribute_path: Utils.route([["petition_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"attributes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
petition_attribute_url: Utils.route([["petition_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"attributes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// petition_attributes => /petitions/:petition_id/attributes(.:format)
  // function(petition_id, options)
  petition_attributes_path: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"attributes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
petition_attributes_url: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"attributes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// petition_beneficiary => /petitions/:petition_id/beneficiary(.:format)
  // function(petition_id, options)
  petition_beneficiary_path: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"beneficiary",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
petition_beneficiary_url: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"beneficiary",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// petition_bundle => /petition_bundles/:id(.:format)
  // function(id, options)
  petition_bundle_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petition_bundles",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
petition_bundle_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petition_bundles",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// petition_case_status => /petitions/:petition_id/case_status(.:format)
  // function(petition_id, options)
  petition_case_status_path: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"case_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
petition_case_status_url: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"case_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// petition_client_data => /petitions/:petition_id/client_data(.:format)
  // function(petition_id, options)
  petition_client_data_path: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"client_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
petition_client_data_url: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"client_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// petition_contact => /petitions/:petition_id/contacts/:name(.:format)
  // function(petition_id, name, options)
  petition_contact_path: Utils.route([["petition_id",true],["name",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"name",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
petition_contact_url: Utils.route([["petition_id",true],["name",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"name",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// petition_contacts => /petitions/:petition_id/contacts(.:format)
  // function(petition_id, options)
  petition_contacts_path: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"contacts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
petition_contacts_url: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"contacts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// petition_documents => /petitions/:petition_id/documents(.:format)
  // function(petition_id, options)
  petition_documents_path: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"documents",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
petition_documents_url: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"documents",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// petition_edit_beneficiary => /petitions/:petition_id/edit_beneficiary(.:format)
  // function(petition_id, options)
  petition_edit_beneficiary_path: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"edit_beneficiary",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
petition_edit_beneficiary_url: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"edit_beneficiary",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// petition_edit_petitioner => /petitions/:petition_id/edit_petitioner(.:format)
  // function(petition_id, options)
  petition_edit_petitioner_path: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"edit_petitioner",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
petition_edit_petitioner_url: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"edit_petitioner",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// petition_edit_point_of_contact => /petitions/:petition_id/edit_point_of_contact(.:format)
  // function(petition_id, options)
  petition_edit_point_of_contact_path: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"edit_point_of_contact",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
petition_edit_point_of_contact_url: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"edit_point_of_contact",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// petition_edit_type => /petitions/:petition_id/edit_type(.:format)
  // function(petition_id, options)
  petition_edit_type_path: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"edit_type",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
petition_edit_type_url: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"edit_type",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// petition_event => /petitions/:petition_id/events/:id(.:format)
  // function(petition_id, id, options)
  petition_event_path: Utils.route([["petition_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"events",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
petition_event_url: Utils.route([["petition_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"events",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// petition_events => /petitions/:petition_id/events(.:format)
  // function(petition_id, options)
  petition_events_path: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"events",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
petition_events_url: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"events",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// petition_evidence_request => /petitions/:petition_id/evidence_request(.:format)
  // function(petition_id, options)
  petition_evidence_request_path: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"evidence_request",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
petition_evidence_request_url: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"evidence_request",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// petition_history => /petitions/:petition_id/history(.:format)
  // function(petition_id, options)
  petition_history_path: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"history",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
petition_history_url: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"history",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// petition_mailer => /petitions/:petition_id/mailers/:id(.:format)
  // function(petition_id, id, options)
  petition_mailer_path: Utils.route([["petition_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"mailers",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
petition_mailer_url: Utils.route([["petition_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"mailers",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// petition_mailers => /petitions/:petition_id/mailers(.:format)
  // function(petition_id, options)
  petition_mailers_path: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"mailers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
petition_mailers_url: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"mailers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// petition_offer => /petitions/:petition_id/offer(.:format)
  // function(petition_id, options)
  petition_offer_path: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"offer",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
petition_offer_url: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"offer",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// petition_overview => /petitions/:petition_id/overview(.:format)
  // function(petition_id, options)
  petition_overview_path: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"overview",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
petition_overview_url: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"overview",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// petition_representative => /petitions/:petition_id/representative(.:format)
  // function(petition_id, options)
  petition_representative_path: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"representative",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
petition_representative_url: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"representative",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// petition_save_beneficiary => /petitions/:petition_id/save_beneficiary(.:format)
  // function(petition_id, options)
  petition_save_beneficiary_path: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"save_beneficiary",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
petition_save_beneficiary_url: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"save_beneficiary",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// petition_save_petitioner => /petitions/:petition_id/save_petitioner(.:format)
  // function(petition_id, options)
  petition_save_petitioner_path: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"save_petitioner",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
petition_save_petitioner_url: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"save_petitioner",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// petition_save_point_of_contact => /petitions/:petition_id/save_point_of_contact(.:format)
  // function(petition_id, options)
  petition_save_point_of_contact_path: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"save_point_of_contact",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
petition_save_point_of_contact_url: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"save_point_of_contact",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// petition_save_type => /petitions/:petition_id/save_type(.:format)
  // function(petition_id, options)
  petition_save_type_path: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"save_type",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
petition_save_type_url: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"save_type",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// petition_set_assignee => /petitions/:petition_id/set_assignee(.:format)
  // function(petition_id, options)
  petition_set_assignee_path: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"set_assignee",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
petition_set_assignee_url: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"set_assignee",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// petition_set_attorney => /petitions/:petition_id/set_attorney(.:format)
  // function(petition_id, options)
  petition_set_attorney_path: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"set_attorney",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
petition_set_attorney_url: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"set_attorney",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// petition_signatory => /petitions/:petition_id/signatory(.:format)
  // function(petition_id, options)
  petition_signatory_path: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"signatory",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
petition_signatory_url: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"signatory",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// petition_template => /petitions/:petition_id/templates/:template_id(.:format)
  // function(petition_id, template_id, options)
  petition_template_path: Utils.route([["petition_id",true],["template_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[3,"template_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
petition_template_url: Utils.route([["petition_id",true],["template_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[3,"template_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// petition_templates => /petitions/:petition_id/templates(.:format)
  // function(petition_id, options)
  petition_templates_path: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"templates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
petition_templates_url: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"templates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// petition_toggle_blocker => /petitions/:petition_id/toggle_blocker(.:format)
  // function(petition_id, options)
  petition_toggle_blocker_path: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"toggle_blocker",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
petition_toggle_blocker_url: Utils.route([["petition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"petition_id",false],[2,[7,"/",false],[2,[6,"toggle_blocker",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// petitions => /petitions(.:format)
  // function(options)
  petitions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
petitions_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// press => /press/:id(.:format)
  // function(id, options)
  press_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"press",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
press_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"press",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// press_index => /press(.:format)
  // function(options)
  press_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"press",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
press_index_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"press",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// proof_of_residencies => /proof_of_residencies(.:format)
  // function(options)
  proof_of_residencies_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"proof_of_residencies",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
proof_of_residencies_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"proof_of_residencies",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// proof_of_residency => /proof_of_residencies/:id(.:format)
  // function(id, options)
  proof_of_residency_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"proof_of_residencies",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
proof_of_residency_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"proof_of_residencies",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// rails_blob_representation => /rails/active_storage/representations/:signed_blob_id/:variation_key/*filename(.:format)
  // function(signed_blob_id, variation_key, filename, options)
  rails_blob_representation_path: Utils.route([["signed_blob_id",true],["variation_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"representations",false],[2,[7,"/",false],[2,[3,"signed_blob_id",false],[2,[7,"/",false],[2,[3,"variation_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
rails_blob_representation_url: Utils.route([["signed_blob_id",true],["variation_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"representations",false],[2,[7,"/",false],[2,[3,"signed_blob_id",false],[2,[7,"/",false],[2,[3,"variation_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], true),
// rails_direct_uploads => /rails/active_storage/direct_uploads(.:format)
  // function(options)
  rails_direct_uploads_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"direct_uploads",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
rails_direct_uploads_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"direct_uploads",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// rails_disk_service => /rails/active_storage/disk/:encoded_key/*filename(.:format)
  // function(encoded_key, filename, options)
  rails_disk_service_path: Utils.route([["encoded_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
rails_disk_service_url: Utils.route([["encoded_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// rails_info => /rails/info(.:format)
  // function(options)
  rails_info_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"info",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
rails_info_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"info",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// rails_info_properties => /rails/info/properties(.:format)
  // function(options)
  rails_info_properties_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"info",false],[2,[7,"/",false],[2,[6,"properties",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
rails_info_properties_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"info",false],[2,[7,"/",false],[2,[6,"properties",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// rails_info_routes => /rails/info/routes(.:format)
  // function(options)
  rails_info_routes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"info",false],[2,[7,"/",false],[2,[6,"routes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
rails_info_routes_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"info",false],[2,[7,"/",false],[2,[6,"routes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// rails_mailers => /rails/mailers(.:format)
  // function(options)
  rails_mailers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"mailers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
rails_mailers_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"mailers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// rails_service_blob => /rails/active_storage/blobs/:signed_id/*filename(.:format)
  // function(signed_id, filename, options)
  rails_service_blob_path: Utils.route([["signed_id",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"blobs",false],[2,[7,"/",false],[2,[3,"signed_id",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
rails_service_blob_url: Utils.route([["signed_id",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"blobs",false],[2,[7,"/",false],[2,[3,"signed_id",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// referrals => /referrals(.:format)
  // function(options)
  referrals_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"referrals",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
referrals_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"referrals",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// refresh_shipment => /shipments/:id/refresh(.:format)
  // function(id, options)
  refresh_shipment_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"shipments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"refresh",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
refresh_shipment_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"shipments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"refresh",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// report_petitions => /petitions/report(.:format)
  // function(options)
  report_petitions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[6,"report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
report_petitions_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[6,"report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// retry_intake_section_step_item => /intakes/:intake_id/sections/:section_name/steps/:step_name/items/:id/retry(.:format)
  // function(intake_id, section_name, step_name, id, options)
  retry_intake_section_step_item_path: Utils.route([["intake_id",true],["section_name",true],["step_name",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"intake_id",false],[2,[7,"/",false],[2,[6,"sections",false],[2,[7,"/",false],[2,[3,"section_name",false],[2,[7,"/",false],[2,[6,"steps",false],[2,[7,"/",false],[2,[3,"step_name",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"retry",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]]),
retry_intake_section_step_item_url: Utils.route([["intake_id",true],["section_name",true],["step_name",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"intake_id",false],[2,[7,"/",false],[2,[6,"sections",false],[2,[7,"/",false],[2,[3,"section_name",false],[2,[7,"/",false],[2,[6,"steps",false],[2,[7,"/",false],[2,[3,"step_name",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"retry",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]], true),
// reupload_intake_section_step_item => /intakes/:intake_id/sections/:section_name/steps/:step_name/items/:id/reupload(.:format)
  // function(intake_id, section_name, step_name, id, options)
  reupload_intake_section_step_item_path: Utils.route([["intake_id",true],["section_name",true],["step_name",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"intake_id",false],[2,[7,"/",false],[2,[6,"sections",false],[2,[7,"/",false],[2,[3,"section_name",false],[2,[7,"/",false],[2,[6,"steps",false],[2,[7,"/",false],[2,[3,"step_name",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reupload",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]]),
reupload_intake_section_step_item_url: Utils.route([["intake_id",true],["section_name",true],["step_name",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"intake_id",false],[2,[7,"/",false],[2,[6,"sections",false],[2,[7,"/",false],[2,[3,"section_name",false],[2,[7,"/",false],[2,[6,"steps",false],[2,[7,"/",false],[2,[3,"step_name",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reupload",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]], true),
// review_intake => /intakes/:id/review(.:format)
  // function(id, options)
  review_intake_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"review",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
review_intake_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"review",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// review_reupload_intake_section_step_item => /intakes/:intake_id/sections/:section_name/steps/:step_name/items/:id/review_reupload(.:format)
  // function(intake_id, section_name, step_name, id, options)
  review_reupload_intake_section_step_item_path: Utils.route([["intake_id",true],["section_name",true],["step_name",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"intake_id",false],[2,[7,"/",false],[2,[6,"sections",false],[2,[7,"/",false],[2,[3,"section_name",false],[2,[7,"/",false],[2,[6,"steps",false],[2,[7,"/",false],[2,[3,"step_name",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"review_reupload",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]]),
review_reupload_intake_section_step_item_url: Utils.route([["intake_id",true],["section_name",true],["step_name",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"intake_id",false],[2,[7,"/",false],[2,[6,"sections",false],[2,[7,"/",false],[2,[3,"section_name",false],[2,[7,"/",false],[2,[6,"steps",false],[2,[7,"/",false],[2,[3,"step_name",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"review_reupload",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]], true),
// revisit_intake => /intakes/:id/revisit(.:format)
  // function(id, options)
  revisit_intake_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"revisit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
revisit_intake_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"revisit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// root => /
  // function(options)
  root_path: Utils.route([], {}, [7,"/",false]),
root_url: Utils.route([], {}, [7,"/",false], true),
// sales => /sales(.:format)
  // function(options)
  sales_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sales",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
sales_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sales",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// sales_deals => /sales/deals(.:format)
  // function(options)
  sales_deals_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sales",false],[2,[7,"/",false],[2,[6,"deals",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
sales_deals_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sales",false],[2,[7,"/",false],[2,[6,"deals",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// save_type_petitions => /petitions/save_type(.:format)
  // function(options)
  save_type_petitions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[6,"save_type",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
save_type_petitions_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[6,"save_type",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// schema => /schema(.:format)
  // function(options)
  schema_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"schema",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
schema_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"schema",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// send_confirmation_instructions_employee => /employees/:id/send_confirmation_instructions(.:format)
  // function(id, options)
  send_confirmation_instructions_employee_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"send_confirmation_instructions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
send_confirmation_instructions_employee_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"send_confirmation_instructions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// send_welcome_email_client => /clients/:id/send_welcome_email(.:format)
  // function(id, options)
  send_welcome_email_client_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"send_welcome_email",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
send_welcome_email_client_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"send_welcome_email",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// setting => /settings/:id(.:format)
  // function(id, options)
  setting_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
setting_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// settings => /settings(.:format)
  // function(options)
  settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
settings_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// shipment => /shipments/:id(.:format)
  // function(id, options)
  shipment_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"shipments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
shipment_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"shipments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// shipments => /shipments(.:format)
  // function(options)
  shipments_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"shipments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
shipments_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"shipments",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// sidekiq_web => /admin/sidekiq
  // function(options)
  sidekiq_web_path: Utils.route([], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[6,"sidekiq",false]]]]),
sidekiq_web_url: Utils.route([], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[6,"sidekiq",false]]]], true),
// sign_in => /sign_in(.:format)
  // function(options)
  sign_in_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
sign_in_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// stay => /stays/:id(.:format)
  // function(id, options)
  stay_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"stays",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
stay_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"stays",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// stays => /stays(.:format)
  // function(options)
  stays_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"stays",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
stays_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"stays",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// submit_deal => /deals/:id/submit(.:format)
  // function(id, options)
  submit_deal_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"deals",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"submit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
submit_deal_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"deals",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"submit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// submit_intake => /intakes/:id/submit(.:format)
  // function(id, options)
  submit_intake_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"submit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
submit_intake_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"submit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// track_new_shipment => /shipments/new/track(.:format)
  // function(options)
  track_new_shipment_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"shipments",false],[2,[7,"/",false],[2,[6,"new",false],[2,[7,"/",false],[2,[6,"track",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
track_new_shipment_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"shipments",false],[2,[7,"/",false],[2,[6,"new",false],[2,[7,"/",false],[2,[6,"track",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// unarchive_deal => /deals/:id/unarchive(.:format)
  // function(id, options)
  unarchive_deal_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"deals",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"unarchive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
unarchive_deal_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"deals",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"unarchive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// unarchive_intake => /intakes/:id/unarchive(.:format)
  // function(id, options)
  unarchive_intake_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"unarchive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
unarchive_intake_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"intakes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"unarchive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// unarchive_petition => /petitions/:id/unarchive(.:format)
  // function(id, options)
  unarchive_petition_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"unarchive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
unarchive_petition_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"petitions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"unarchive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// unsubmit_deal => /deals/:id/unsubmit(.:format)
  // function(id, options)
  unsubmit_deal_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"deals",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"unsubmit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
unsubmit_deal_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"deals",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"unsubmit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// update_metadata_deal => /deals/:id/update_metadata(.:format)
  // function(id, options)
  update_metadata_deal_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"deals",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_metadata",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
update_metadata_deal_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"deals",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_metadata",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// update_rails_disk_service => /rails/active_storage/disk/:encoded_token(.:format)
  // function(encoded_token, options)
  update_rails_disk_service_path: Utils.route([["encoded_token",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
update_rails_disk_service_url: Utils.route([["encoded_token",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// user => /users/:id(.:format)
  // function(id, options)
  user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
user_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// user_confirmation => /users/confirmation(.:format)
  // function(options)
  user_confirmation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"confirmation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
user_confirmation_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"confirmation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// user_password => /users/password(.:format)
  // function(options)
  user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
user_password_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// user_registration => /users(.:format)
  // function(options)
  user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
user_registration_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// user_session => /users/sign_in(.:format)
  // function(options)
  user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
user_session_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// users => /users(.:format)
  // function(options)
  users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
users_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// users_current => /users/current(.:format)
  // function(options)
  users_current_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"current",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
users_current_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"current",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// visa_bulletin => /visa_bulletin(.:format)
  // function(options)
  visa_bulletin_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"visa_bulletin",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
visa_bulletin_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"visa_bulletin",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// visa_bulletin_application_date_status => /visa_bulletin/application_date_status(.:format)
  // function(options)
  visa_bulletin_application_date_status_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"visa_bulletin",false],[2,[7,"/",false],[2,[6,"application_date_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
visa_bulletin_application_date_status_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"visa_bulletin",false],[2,[7,"/",false],[2,[6,"application_date_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// visa_status => /visa_statuses/:id(.:format)
  // function(id, options)
  visa_status_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"visa_statuses",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
visa_status_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"visa_statuses",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// visa_statuses => /visa_statuses(.:format)
  // function(options)
  visa_statuses_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"visa_statuses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
visa_statuses_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"visa_statuses",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// webmerge_dictionary => /webmerge/dictionary(.:format)
  // function(options)
  webmerge_dictionary_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"webmerge",false],[2,[7,"/",false],[2,[6,"dictionary",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
webmerge_dictionary_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"webmerge",false],[2,[7,"/",false],[2,[6,"dictionary",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// webmerge_dictionary_update => /webmerge/dictionary_update(.:format)
  // function(options)
  webmerge_dictionary_update_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"webmerge",false],[2,[7,"/",false],[2,[6,"dictionary_update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
webmerge_dictionary_update_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"webmerge",false],[2,[7,"/",false],[2,[6,"dictionary_update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// webmerge_field_maps => /webmerge/field_maps(.:format)
  // function(options)
  webmerge_field_maps_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"webmerge",false],[2,[7,"/",false],[2,[6,"field_maps",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
webmerge_field_maps_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"webmerge",false],[2,[7,"/",false],[2,[6,"field_maps",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// webmerge_generate => /webmerge/generate(.:format)
  // function(options)
  webmerge_generate_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"webmerge",false],[2,[7,"/",false],[2,[6,"generate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
webmerge_generate_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"webmerge",false],[2,[7,"/",false],[2,[6,"generate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// webmerge_template => /webmerge/template/:id(.:format)
  // function(id, options)
  webmerge_template_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"webmerge",false],[2,[7,"/",false],[2,[6,"template",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
webmerge_template_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"webmerge",false],[2,[7,"/",false],[2,[6,"template",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// webmerge_templates => /webmerge/templates(.:format)
  // function(options)
  webmerge_templates_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"webmerge",false],[2,[7,"/",false],[2,[6,"templates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
webmerge_templates_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"webmerge",false],[2,[7,"/",false],[2,[6,"templates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// workflow => /workflows/:id(.:format)
  // function(id, options)
  workflow_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"workflows",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
workflow_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"workflows",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// workflow_history => /workflows/:workflow_id/history(.:format)
  // function(workflow_id, options)
  workflow_history_path: Utils.route([["workflow_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"workflows",false],[2,[7,"/",false],[2,[3,"workflow_id",false],[2,[7,"/",false],[2,[6,"history",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
workflow_history_url: Utils.route([["workflow_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"workflows",false],[2,[7,"/",false],[2,[3,"workflow_id",false],[2,[7,"/",false],[2,[6,"history",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// workflows => /workflows(.:format)
  // function(options)
  workflows_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"workflows",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
workflows_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"workflows",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// worksite => /worksites/:id(.:format)
  // function(id, options)
  worksite_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"worksites",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
worksite_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"worksites",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// worksites => /worksites(.:format)
  // function(options)
  worksites_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"worksites",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
worksites_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"worksites",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true)}
;
      routes.configure = function(config) {
        return Utils.configure(config);
      };
      routes.config = function() {
        return Utils.config();
      };
      routes.default_serializer = function(object, prefix) {
        return Utils.default_serializer(object, prefix);
      };
      return Object.assign({
        "default": routes
      }, routes);
    }
  };

  result = Utils.make();

  if (typeof define === "function" && define.amd) {
    define([], function() {
      return result;
    });
  } else if (typeof module !== "undefined" && module !== null) {
    try {
      module.exports = result;
    } catch (error1) {
      error = error1;
      if (error.name !== 'TypeError') {
        throw error;
      }
    }
  } else {
    Utils.namespace(this, null, result);
  }

  return result;

}).call(this);
